import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { ApiDoctor } from "../../../services/Doctors";

export type ReferrerState = {
  searchProcess: boolean;
  isSearched: boolean;
  lastName: string | null;

  doctors: [{
    isSelected: boolean;
    ulid: string;
    userProfile: {
      lastName: string;
      firstName: string;
      middleName: string;
    };
  }] | null;

  selectedDoctor: {
    ulid: string;
    userProfile: {
      lastName: string;
      firstName: string;
      middleName: string;
    };
  };
  date: string | null;

  isCreateNewDoctorProfile: boolean;
};

const initialState: ReferrerState = {
  searchProcess: false,
  isSearched: false,
  lastName: null,
  doctors: null,
  selectedDoctor: {
    ulid: "",
    userProfile: {
      lastName: "",
      firstName: "",
      middleName: "",
    },
  },
  date: null,
  isCreateNewDoctorProfile: false,
};

export const referrerSlice = createSlice({
  name: "referrer",
  initialState,
  reducers: {
    resetReferrer : () => initialState,

    setSearchProcess: (state, action: PayloadAction<boolean>) => {
      state.searchProcess = action.payload;
    },

    setIsSearched: (state, action: PayloadAction<boolean>) => {
      state.isSearched = action.payload;
    },

    setSelectedDoctorLastName: (state, action: PayloadAction<string>) => {
      state.selectedDoctor.userProfile.lastName = action.payload;
    },

    setSelectedDoctorFirstName: (state, action: PayloadAction<string>) => {
      state.selectedDoctor.userProfile.firstName = action.payload;
    },

    setSelectedDoctorMiddleName: (state, action: PayloadAction<string>) => {
      state.selectedDoctor.userProfile.middleName = action.payload;
    },

    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },

    setDoctors: (state, action: PayloadAction<Array<ApiDoctor>>) => {
      console.log('setDoctors', action.payload);
      if (!action.payload) return;
      // @ts-ignore
      state.doctors = action.payload.map((doctor) => ({
        isSelected: false,
        ulid: doctor.ulid,
        userProfile: {
          lastName: doctor.user_profile.last_name,
          firstName: doctor.user_profile.first_name,
          middleName: doctor.user_profile.middle_name,
        },
      }));
    },
    
    setDoctorToggled: (state, action: PayloadAction<any>) => {
      // @ts-ignore
      state.doctors = state.doctors.map((doctor, index) => ({
        ...doctor,
        isSelected: index === action.payload,
      }));
    },

    setSelectedDoctor: (state) => {
      const selected = state.doctors?.find(doctor => doctor.isSelected);
      if (selected) {
        state.selectedDoctor = { ...selected };
      }
    },

    setIsCreateNewDoctorProfile: (state, action: PayloadAction<boolean>) => {
      state.isCreateNewDoctorProfile = action.payload;
    },

    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
  },
});

export const {
  resetReferrer,
  setSearchProcess,
  setIsSearched,
  setSelectedDoctorFirstName,
  setSelectedDoctorLastName,
  setSelectedDoctorMiddleName,
  setLastName,
  setDoctors,
  setDoctorToggled,
  setSelectedDoctor,
  setIsCreateNewDoctorProfile,
  setDate,
} = referrerSlice.actions;

export const referrerReducer = referrerSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const searchProcessSelector = createSelector(
  getState,
  (state) => state.referrer.searchProcess
);

export const isSearchedSelector = createSelector(
  getState,
  (state) => state.referrer.isSearched
);

export const isCreateNewDoctorProfileSelector = createSelector(
  getState,
  (state) => state.referrer.isCreateNewDoctorProfile
);

export const lastNameSelector = createSelector(
  getState,
  (state) => state.referrer.lastName
);

export const doctorsSelector = createSelector(
  getState,
  (state) => state.referrer.doctors
);

export const selectedDoctorSelector = createSelector(
  getState,
  (state) => state.referrer.selectedDoctor
);

export const dateSelector = createSelector(
  getState,
  (state) => state.referrer.date
);
