import styled from "styled-components";
import { Input } from "../../../../../../componentsNew/Input";
import { Button } from "../../../../../../componentsNew/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  foreignSearchPassportOrIdSelector,
  selectedPatientConfirmedSelector,
  resetSearch,
  setForeignSearchPassportOrId,
  setSearchProcessInitiated,
} from "../../../../redux/patient";
import { usePatients } from "../../../../hooks/usePatients";

export const ForeignSearchPersonalData = () => {
  const dispatch = useDispatch();

  const foreignSearchPassportOrId = useSelector(
    foreignSearchPassportOrIdSelector
  );

  const { searchPatients } = usePatients();

  const onSearch = async () => {
    dispatch(setSearchProcessInitiated(true));

    await searchPatients({
      passportId: foreignSearchPassportOrId || "",
    })
  };

  const isSelectedPatientConfirmed = useSelector(selectedPatientConfirmedSelector);

  const isFilledPatientSearchDetails = Boolean(
    foreignSearchPassportOrId
  );

  const onClear = () => {
    dispatch(resetSearch());
  };

  return (
    <StyledPersonalDataContainer>
      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Паспорт или иной ID"
            required={true}
            placeholder="461122222A"
            value={foreignSearchPassportOrId || ""}
            onChange={(event) => {
              dispatch(setForeignSearchPassportOrId(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledPersonalDataButtonsContainer>
        <Button
          size="L"
          type="SECONDARY"
          text="Очистить"
          onClick={() => {
            onClear();
          }}
          disabled={isSelectedPatientConfirmed}
        />

        <Button
          size="L"
          type="PRIMARY"
          text="Найти пациента"
          onClick={() => {
            onSearch();
          }}
          disabled={!isFilledPatientSearchDetails || isSelectedPatientConfirmed}
        />
      </StyledPersonalDataButtonsContainer>
    </StyledPersonalDataContainer>
  );
};

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledPersonalDataContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const StyledPersonalDataButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

const StyledInputContainer2 = styled.div`
  width: 100%;
`;
