import { FC, useCallback, useMemo } from 'react';
import {
  TDateInfo,
  TDateOfCompletion,
  TReferralSideDataWr,
  TRowWr,
} from './styled';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Department, Organization } from '../../../store/organizations/model';
import { getServiceUsers } from '../../../store/serviceUsers/selectors';
import ReadOnlyInput from '../../../components/shared/ReadOnlyInput';

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

const ReferralSideData: FC<IComponentProps> = (data) => {
  const doctorsList = useSelector(getServiceUsers);

  const getUserOrgDep = useCallback(
    (profile: any) => {
      if (!data?.organizations || !profile) return { org: '', dep: '' };
      let orgData, depData;
      let orgId = profile?.organization ?? '';
      let depId = profile?.department ?? '';
      let orgList = data.organizations ?? [];
      if (orgId && orgList.length)
        orgData = orgList.find((org: Organization) => org.id === +orgId);
      if (orgData && depId)
        depData = orgData.organizationDepartment.find(
          (dep: Department) => dep.id === depId
        );

      return {
        org: orgData?.nameLocalRus ?? '',
        dep: depData?.nameLocalRus ?? '',
      };
    },
    [data?.organizations]
  );

  const doctorCustomOrg = useMemo(
    () => data?.doctorReferral?.userProfile?.organizationCustom,
    [data?.doctorReferral?.userProfile]
  );

  const getUserFullName = useCallback((profile: any, addTitle?: boolean) => {
    if (!profile) return '';
    let firstName = profile['firstName'] ?? '';
    let middleName = profile['middleName'] ?? '';
    let lastName = profile['lastName'] ?? '';
    let title = addTitle ? `, ${profile['title'] ?? ''}` : '';

    return `${lastName} ${firstName} ${middleName}${title}`;
  }, []);

  const doctorOrgOrDep = useMemo(
    () => getUserOrgDep(data?.doctorReferral?.userProfile),
    [data?.doctorReferral?.userProfile, getUserOrgDep]
  );

  const [fullDoctorName] = useMemo(() => {
    let doctorProfile = data?.doctorReferral?.userProfile;
    let doctorName = getUserFullName(doctorProfile);

    let referralCreatorID = data?.referralCreator;
    let referralCreatorProfile = doctorsList?.find(
      (item) => item.ulid === referralCreatorID
    )?.userProfile;
    let isSamePerson = doctorProfile?.ulid === referralCreatorProfile?.ulid;
    let referralCreatorName = isSamePerson
      ? ''
      : getUserFullName(referralCreatorProfile, true);

    return [doctorName, referralCreatorName];
  }, [data, doctorsList, getUserFullName]);

  const createdDate = useMemo(() => {
    if (!data?.dateCreated) return '';
    return format(new Date(data.dateCreated), 'dd.MM.yyyy - HH:mm:ss');
  }, [data?.dateCreated]);

  const createdDateOfAcquisition = useMemo(() => {
    if (!data?.dateOfAcquisition) return '';
    return format(new Date(data.dateOfAcquisition), 'dd.MM.yyyy');
  }, [data?.dateOfAcquisition]);

  return (
    <TReferralSideDataWr>
      <TRowWr direction={'space-between'}>
        <TDateOfCompletion>
          {!!createdDate && (
            <>
              <span>Дата создания в системе: </span>
              <TDateInfo>{createdDate}</TDateInfo>
            </>
          )}
        </TDateOfCompletion>
      </TRowWr>
      <ReadOnlyInput width={'49%'} label='УЗ'>
        {doctorCustomOrg || (doctorOrgOrDep.org ?? '')}
      </ReadOnlyInput>
      <ReadOnlyInput width={'49%'} label='Отделение'>
        {doctorOrgOrDep.dep ?? ''}
      </ReadOnlyInput>
      <ReadOnlyInput width={'49%'} label='ФИО врача'>
        {fullDoctorName ?? ''}
      </ReadOnlyInput>
      <ReadOnlyInput width={'49%'} label='Дата направления на исследование'>
        {createdDateOfAcquisition ?? ''}
      </ReadOnlyInput>
    </TReferralSideDataWr>
  );
};

export default ReferralSideData;
