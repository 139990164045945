import styled from 'styled-components';
import { StepState, StepsType, Step as StepType } from './types/Step';
import { StepWithCircle, Step } from './components/Step';
import { getContentValue, getStepperColor, getStepWidth } from './utils';

type Props = {
  type: StepsType;
  steps: Array<StepType>;
  onChange?: (stepCode: string) => void;
};

type StepProps = {
  step: StepType;
  index: number;
  onChange: (stepCode?: string) => void;
  type: StepsType;
};

type StepComponentType = (props: StepProps) => JSX.Element;

const stepComponents: Record<StepsType, StepComponentType> = {
  [StepsType.CIRCLE_WITH_NUMBER]: StepWithCircle,
  [StepsType.STEPPER_SECONDARY]: Step,
  [StepsType.CIRCLE]: StepWithCircle,
};

export const Steps: React.FC<Props> = (props) => {
  const StepComponent = stepComponents[props.type] || null;

  return (
    <StyledContainer>
      <StyledTopContainerList>
        {props.steps.map((step, index) => {
          return (
            <StyledElement
              key={step.code}
              stepState={step.state}
              type={props.type}
            >
              {StepComponent ? (
                <StepComponent
                  step={step}
                  index={index + 1}
                  onChange={() => props.onChange && props.onChange(step.code)}
                  type={props.type}
                />
              ) : null}
            </StyledElement>
          );
        })}
      </StyledTopContainerList>
    </StyledContainer>
  );
};

const StyledElement = styled.li<{ stepState: StepState; type: StepsType }>`
  min-width: ${(props) => getStepWidth(props.type)};
  width: auto;
  flex-grow: 1;

  height: 50px;
  position: relative;
  background-color: ${(props) => getStepperColor(props.stepState)};

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: -10px;
  clip-path: polygon(
    92.08% 0%,
    99.956% 48%,
    99.956% 52%,
    92.08% 100%,
    0% 100%,
    7.5% 52%,
    7.5% 48%,
    0% 0%
  );

  &:before {
    content: ${(props) => getContentValue(props.stepState)};
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    width: 99%;
    height: 48px;
    background-color: white;
    clip-path: inherit;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  background-color: white;
`;

const StyledTopContainerList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  gap: 0px;
  padding: 0;
  margin: 0;

  li {
    &:first-child {
      clip-path: polygon(
        92.08% 0%,
        99.956% 48%,
        99.956% 52%,
        92.08% 100%,
        0% 100%,
        0% 0%
      );
    }

    &:last-child {
      clip-path: polygon(
        100% 0%,
        100% 100%,
        0% 100%,
        7.5% 52%,
        7.5% 48%,
        0% 0%
      );
    }
  }
`;
