import styled from "styled-components";
import { Textarea } from "../../../../../../componentsNew/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  clinicalDiagnosisSelector,
  setClinicalDiagnosis,
} from "../../../../redux/clinicalData";

export const ClinicalDiagnosis = () => {
  const dispatch = useDispatch();

  const clinicalDiagnosis = useSelector(clinicalDiagnosisSelector);

  return (
    <StyledRowContainer>
      <StyledTextareaContainer>
        <Textarea
          title="Клинический диагноз"
          required={true}
          placeholder="Диагноз, указанный направителем, должен включать стадию, 
информацию о проведенном лечении и иные релевантные данные"
          value={clinicalDiagnosis || ""}
          onChange={(event) => {
            console.log(event.target.value); dispatch(setClinicalDiagnosis(event.target.value));
          }}
        />
      </StyledTextareaContainer>
    </StyledRowContainer>
  );
};

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledTextareaContainer = styled.div`
  width: 100%;
`;
