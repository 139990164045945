import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { ApiMarker } from "../../../services/Markers";

export type MarkersState = {
  markers: Array<ApiMarker>;
  selectedMarkers: Array<ApiMarker>;
};

const initialState: MarkersState = {
  markers: [],
  selectedMarkers: [],
};

export const markersSlice = createSlice({
  name: "markers",
  initialState,
  reducers: {
    resetMarkers: () => initialState,

    setMarkers: (state, action: PayloadAction<Array<ApiMarker>>) => {
      state.markers = action.payload;
    },

    toggleMarker: (state, action: PayloadAction<ApiMarker>) => {
      if (
        state.selectedMarkers.find((marker) => marker.id === action.payload.id)
      ) {
        state.selectedMarkers = state.selectedMarkers.filter(
          (marker) => marker.id !== action.payload.id
        );
      } else {
        state.selectedMarkers = [...state.selectedMarkers, action.payload];
      }
    },
  },
});

export const { toggleMarker, setMarkers, resetMarkers } = markersSlice.actions;

export const markersReducer = markersSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const markersSelector = createSelector(
  getState,
  (state) => state.markers.markers
);

export const selectedMarkersSelector = createSelector(
  getState,
  (state) => state.markers.selectedMarkers
);
