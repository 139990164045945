import styled from 'styled-components';
import { Colors } from '../../../../styles/themeNew';

import { StepState, StepsType, Step as StepType } from '../../types/Step';
import { ReactElement } from 'react';

type Props = {
  type: StepsType;
  step: StepType;
  index: number;
  textContainer?: ReactElement;
  onChange: () => void;
};

export const Step = (props: Props) => {
  return (
    <StyledContainer onClick={() => props.onChange()} key={props.step.code}>
      <StyledPointerContainer>
        <StyledTextContainer stepState={props.step.state}>
          {props.step.name}
        </StyledTextContainer>
      </StyledPointerContainer>
    </StyledContainer>
  );
};

export const StepWithCircle = (props: Props) => {
  return (
    <StyledContainer onClick={() => props.onChange()} key={props.step.code}>
      {props.step.state === StepState.WITH_SUCCESS && (
        <StyledPointerContainer>
          <svg
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect x='1' y='1' width='28' height='28' rx='14' fill='#0ED962' />
            <rect
              x='1'
              y='1'
              width='28'
              height='28'
              rx='14'
              stroke='#0ED962'
              strokeWidth='2'
            />
            <path
              d='M10 14.6923C10 14.6923 13.5456 19 13.9473 19C14.3489 19 20 11 20 11'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
            />
          </svg>
          <StyledTextContainer stepState={props.step.state}>
            {props.step.name}
          </StyledTextContainer>
        </StyledPointerContainer>
      )}

      {props.step.state !== StepState.WITH_SUCCESS && (
        <StyledPointerContainer>
          <StyledCircleContainer stepState={props.step.state}>
            {props.index}
          </StyledCircleContainer>
          <StyledTextContainer stepState={props.step.state}>
            {props.step.name}
          </StyledTextContainer>
        </StyledPointerContainer>
      )}
    </StyledContainer>
  );
};

export const StyledPointerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-left: 30px;
`;

export const StyledContainer = styled.div`
  position: relative;
`;

const getBackgroundColor = (stepState: StepState) => {
  if (stepState === StepState.SELECTED) {
    return 'transparent';
  }

  if (stepState === StepState.WITH_SUCCESS) {
    return Colors.GREEN[500];
  }

  if (stepState === StepState.WITH_WARNING) {
    return Colors.ORANGE[400];
  }

  return 'transparent';
};

const getBorderColor = (stepState: StepState) => {
  if (stepState === StepState.SELECTED) {
    return 'white';
  }

  if (stepState === StepState.DISABLED) {
    return Colors.MONOCHROM[200];
  }

  if (stepState === StepState.WITH_WARNING) {
    return Colors.ORANGE[400];
  }

  return Colors.MONOCHROM[200];
};

const getTextColor = (stepState: StepState) => {
  if (stepState === StepState.SELECTED) {
    return 'white';
  }

  if (stepState === StepState.DISABLED) {
    return Colors.MONOCHROM[400];
  }

  if (stepState === StepState.WITH_WARNING) {
    return 'white';
  }

  if (stepState === StepState.WITH_SUCCESS) {
    return Colors.MONOCHROM[950];
  }

  return Colors.MONOCHROM[400];
};

const StyledCircleContainer = styled.div<{ stepState: StepState }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;

  border-radius: 50%;
  border: 2px solid ${(props) => getBorderColor(props.stepState)};
  background: ${(props) => getBackgroundColor(props.stepState)};

  color: ${(props) => getTextColor(props.stepState)};
  font-size: 14px;
  font-weight: 600;

  cursor: pointer;
`;

const StyledTextContainer = styled.div<{ stepState: StepState }>`
  max-width: 101px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: ${(props) => getTextColor(props.stepState)};
`;
