import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";

export type CommonState = {
  createSuccessModalActive: boolean;
  createQuestionModalActive: boolean;
  createdReferralNumber: string | null;
  createReferralErrors: any | null;
};

const initialState: CommonState = {
  createSuccessModalActive: false,
  createQuestionModalActive: false,
  createdReferralNumber: null,
  createReferralErrors: null,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetCommon: () => initialState,

    setCreateSuccessModalActive: (state, action: PayloadAction<boolean>) => {
      state.createSuccessModalActive = action.payload;
    },
    setCreateQuestionModalActive: (state, action: PayloadAction<boolean>) => {
      state.createQuestionModalActive = action.payload;
    },

    setCreatedReferralNumber: (state, action: PayloadAction<string | null>) => {
      state.createdReferralNumber = action.payload;
    },

    setCreateReferralErrors: (state, action: PayloadAction<any | null>) => {
      state.createReferralErrors = action.payload;
    },
  },
});

export const {
  resetCommon,
  setCreateSuccessModalActive,
  setCreateQuestionModalActive,
  setCreatedReferralNumber,
  setCreateReferralErrors,
} = commonSlice.actions;

export const commonReducer = commonSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const createQuestionModalActiveSelector = createSelector(
  getState,
  (state) => state.common.createQuestionModalActive
);

export const createSuccessModalActiveSelector = createSelector(
  getState,
  (state) => state.common.createSuccessModalActive
);

export const createdReferralNumberSelector = createSelector(
  getState,
  (state) => state.common.createdReferralNumber
);

export const createReferralErrorsSelector = createSelector(
  getState,
  (state) => state.common.createReferralErrors
);
