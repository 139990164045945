import { FC, useCallback, useState } from 'react';
import arrowSelectIcon from '../../../icons/details-arrow.svg';
import defaultStatusIcon from '../../../icons/cross-in-circle-default-filled.svg';
import {
  TDetailsContent,
  TSummaryContent,
  TDetailsItemWr,
  TDetailsFormWr,
  TSummaryIcon,
  TSelectAll,
  TCheckbox,
  TDetails,
  TSummary,
  TSummaryIconWrapper,
} from './styled';
import { IDetailsData } from '../../../containers/MolecularProfilingDetailsPage/MolecularProfilingDetailsPage';
import { iconAndStatus } from '../../../containers/MolecularProfilingDetailsPage/data';

interface IDetails {
  detailsData: IDetailsData[];
  closeAllTabs: boolean;
  isSelected: boolean;
}
const Details: FC<IDetails> = ({
  detailsData,
  closeAllTabs,
  isSelected,
}) => {
  const statusIconSrc = (dataStatus: string, iconAndStatus: any) => {
    if (!iconAndStatus) return null;
    const allStatuses = Object.keys(iconAndStatus);
    const status = allStatuses.find((item) => dataStatus.includes(item));
    if (status) return iconAndStatus[status];
    return defaultStatusIcon;
  };
  const [isAllChecked, setAllChecked] = useState(false);
  const [isSelectedOptions, setSelectedOptions] = useState([0]);

  const handleSelectAllCheckboxes = useCallback(() => {
    if (!isAllChecked) {
      const allAvailableOptions = detailsData.map((item: any) => item.id);
      setSelectedOptions(allAvailableOptions);
      return setAllChecked(true);
    }
    setSelectedOptions([0]);
    return setAllChecked(false);
  }, [setSelectedOptions, setAllChecked, isAllChecked, detailsData]);

  const handleSummaryChecked = useCallback(
    (index) => {
      if (!index) return;
      const hasSelected = isSelectedOptions.includes(index);
      if (hasSelected)
        return setSelectedOptions(
          isSelectedOptions.filter((options) => options !== index)
        );
      return setSelectedOptions(isSelectedOptions.concat(index));
    },
    [isSelectedOptions, setSelectedOptions]
  );

  return (
    <TDetailsFormWr>
      {isSelected && (
        <TSelectAll>
          <TCheckbox
            checked={isAllChecked}
            onChange={handleSelectAllCheckboxes}
          />
          Выбрать все
        </TSelectAll>
      )}

      {detailsData.map((data: IDetailsData, index: number, dataArr: any[]) => {
        let iconSrc = statusIconSrc(data?.status, iconAndStatus);
        let isOpen = !!closeAllTabs;

        if (!closeAllTabs) {
          if (!Object.keys(data?.content ?? {}).length) isOpen = false;
          // else isOpen = iconSrc.includes('attention');

          if (
            data.status === 'done' &&
            !Object.keys(dataArr?.[index + 1]?.content ?? {}).length
          ) {
            isOpen = true;
          }
        }
        if (data?.status.includes('finish')) isOpen = true;

        return (
          <TDetailsItemWr key={data.id}>
            <TDetails open={isOpen}>
              <TSummary>
                <TSummaryContent isSelected={isSelected}>
                  {isSelected && (
                    <TCheckbox
                      checked={isSelectedOptions.includes(data.id)}
                      onChange={() => handleSummaryChecked(data.id)}
                    />
                  )}
                  {data?.title}
                </TSummaryContent>
                <TSummaryIconWrapper>
                  {data?.hasStatus && iconAndStatus && (
                    <TSummaryIcon src={iconSrc} />
                  )}
                  <TSummaryIcon className='arrow' src={arrowSelectIcon} />
                </TSummaryIconWrapper>
              </TSummary>
              <TDetailsContent>
                {data.component && data.component(data?.content)}
              </TDetailsContent>
            </TDetails>
          </TDetailsItemWr>
        );
      })}
    </TDetailsFormWr>
  );
};

export default Details;
