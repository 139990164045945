import { StepState } from '../../../componentsNew/Steps/types/Step';
import { FLOW_STEPS_STATUSES } from '../../../fixtures/StepsMolecularProfilingPage';

export class RefferalStepStateChecker {
  static getBioMaterilReceipt(stepCurrentIndex: number) {
    if (stepCurrentIndex === FLOW_STEPS_STATUSES.bio_pending)
      return StepState.SELECTED;

    if(stepCurrentIndex > 1) return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getMorphologicalCharacterization(stepCurrentIndex: number) {
    if (stepCurrentIndex === FLOW_STEPS_STATUSES.morphology_pending)
      return StepState.SELECTED;

    if(stepCurrentIndex > 2) return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getResearchImplementation(stepCurrentIndex: number) {
    // currentStep,
    // selectedPatient,
    // isSelectedPatientConfirmed,
    // currentStep: { code: string | null; name: string | null };
    // selectedPatient: PatientState["selectedPatient"];
    // isSelectedPatientConfirmed: PatientState["selectedPatientConfirmed"];
    // if (currentStep.code === "PATIENT") {
    //   return StepState.SELECTED;
    // }

    // if (selectedPatient && isSelectedPatientConfirmed) {
    //   return StepState.WITH_SUCCESS;
    // }
    if (stepCurrentIndex === FLOW_STEPS_STATUSES.analysis_pending)
      return StepState.SELECTED;

    if(stepCurrentIndex > 2) return StepState.WITH_SUCCESS;

    return StepState.DISABLED;
  }

  static getConclusionFormation() {
    // currentStep,
    // selectedDoctor,
    // date,
    // currentStep: { code: string | null; name: string | null };
    // selectedDoctor: ReferrerState["selectedDoctor"];
    // date: ReferrerState["date"];
    // if (currentStep.code === "REFERRER") {
    //   return StepState.SELECTED;
    // }

    // if ((selectedDoctor.ulid || (selectedDoctor.userProfile.firstName && selectedDoctor.userProfile.lastName && selectedDoctor.userProfile.middleName)) && date) {
    //   return StepState.WITH_SUCCESS;
    // }

    return StepState.DISABLED;
  }

  static getReferralClosed() {
    // currentStep,
    // clinicalData,
    // currentStep: { code: string | null; name: string | null };
    // clinicalData: ClinicalDataState;
    // if (currentStep.code === "CLINICAL_DATA") {
    //   return StepState.SELECTED;
    // }

    // const isClinicalDataComplete = (data: ClinicalDataState): boolean => {
    //   for (const key in data) {
    //     const value = (data as any)[key];
    //     if (
    //       value === null ||
    //       value === undefined ||
    //       (Array.isArray(value) && value.length === 0)
    //     ) {
    //       return false;
    //     }
    //   }
    //   return true;
    // };

    // if (isClinicalDataComplete(clinicalData)) {
    //   return StepState.WITH_SUCCESS;
    // }

    return StepState.DISABLED;
  }
}
