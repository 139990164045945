import { TTableRowWR } from '../styled';
import { FC } from 'react';

type CodeTableCellProps = {
  block?: any;
  slide?: any;
  color: string;
};

const CodeTableCell: FC<CodeTableCellProps> = (props) => {
  const { block, slide, color } =
    props;
  let isBlock = !!block?.code;
  let isSlide = !!slide?.code;

  if (!isBlock && !isSlide)
    return (
      <td className={color}>
        <TTableRowWR>
          <span>--</span>
        </TTableRowWR>
      </td>
    );

  return (
    <td className={color}>
      <TTableRowWR>
        <span>{isBlock ? block.code : slide.code}</span>
      </TTableRowWR>
    </td>
  );
};

export default CodeTableCell;
