import styled from "styled-components";
import {
  dateSelector,
  lastNameSelector,
  selectedDoctorSelector,
  setSelectedDoctorFirstName,
  setSelectedDoctorMiddleName,
  setDate,
  setSelectedDoctorLastName,
} from "../../../../redux/referrer";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export const DoctorCreateNewProfile = () => {
  const dispatch = useDispatch();
  const selectedDoctor = useSelector(selectedDoctorSelector);
  const searchLastName = useSelector(lastNameSelector);

  const date = useSelector(dateSelector);

  useEffect(() => {
    dispatch(setSelectedDoctorLastName(searchLastName || ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Создайте новый профиль специалиста</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Фамилия"
            required={true}
            placeholder=""
            value={selectedDoctor.userProfile.lastName || ""}
            disabled={true}
            onChange={(event) => {}}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Имя"
            required={true}
            placeholder="Иван"
            value={selectedDoctor.userProfile.firstName || ""}
            onChange={(event) => {
              dispatch(setSelectedDoctorFirstName(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Отчество"
            required={true}
            placeholder="Иванович"
            value={selectedDoctor.userProfile.middleName || ""}
            onChange={(event) => {
              dispatch(setSelectedDoctorMiddleName(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <Title level="3">Дата направления на исследование</Title>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Datepicker
            required={true}
            title="Дата"
            placeholder=""
            value={date || ""}
            onChange={(event) => {
              dispatch(setDate(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;
