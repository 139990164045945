import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { ApiLaboratory } from "../../../services/Laboratories";

export type LaboratoryState = {
  laboratories: Array<ApiLaboratory>;

  selectedLaboratory: {
    id: number | null;
    name: string | null;
  } | null;
};

const initialState: LaboratoryState = {
  laboratories: [],

  selectedLaboratory: null,
};

export const laboratorySlice = createSlice({
  name: "laboratory",
  initialState,
  reducers: {
    resetLaboratory: () => initialState,

    setLaboratories: (state, action: PayloadAction<Array<ApiLaboratory>>) => {
      state.laboratories = action.payload;
    },
    setLaboratory: (
      state,
      action: PayloadAction<{ id: number; name: string }>
    ) => {
      state.selectedLaboratory = {
        id: action.payload.id,
        name: action.payload.name,
      };
    },
  },
});

export const { setLaboratory, setLaboratories, resetLaboratory } = laboratorySlice.actions;

export const laboratoryReducer = laboratorySlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const laboratorySelector = createSelector(
  getState,
  (state) => state.laboratory.selectedLaboratory
);

export const laboratoriesSelector = createSelector(
  getState,
  (state) => state.laboratory.laboratories
);
