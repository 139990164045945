import { useState, useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { Select } from "../../../../../../componentsNew/Select";

import {
  setPatientToSelectToggled,
  selectedPatientSelector,
  selectedPatientConfirmedSelector,
  setSelectedPatient,
  setPatientAmbulatoryCardToggled,
  setPatientAmbulatoryCardUntoggled,
  setSelectedPatientConfirmed,
  selectedAmbulatoryCardSelector,
  setSelectedAmbulatoryCardNumber,
  setSelectedAmbulatoryCardOrganizationId,
  setSelectedAmbulatoryCardOrganizationName,
} from "../../../../redux/patient";

import { ClinicsService } from "../../../../../../services/Clinics";
import { CountriesService } from "../../../../../../services/Countries";

import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { Description } from "../../../../../../componentsNew/Description";

import { Button } from "../../../../../../componentsNew/Button";
import { Radio } from "../../../../../../componentsNew/Radio";

import { GENDERS, GENDER_MALE } from "../../../../../../constants/genders";
import { useHttpClient } from "../../../../../../hooks/useHttpClient";
import { InputWithRadioToggle } from "../../../../../../componentsNew/InputWithRadioToggle";

export const FoundForeignProfile = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();
  const [clinics, setClinics] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);

  const selectedPatient = useSelector(selectedPatientSelector);
  const selectedAmbulatoryCard = useSelector(selectedAmbulatoryCardSelector);
  const isSelectedPatientConfirmed = useSelector(selectedPatientConfirmedSelector);

  const effect = async () => {
    dispatch(setPatientToSelectToggled(0));
    dispatch(setSelectedPatient());

    const clinicsService = new ClinicsService(httpClient);
    const _clinics = await clinicsService.searchClinics();

    const countriesService = new CountriesService(httpClient)
    const _countries = await countriesService.getCountries();

    if (_clinics?.length) {
      setClinics(_clinics);
    }

    if (_countries?.length) {
      setCountries(_countries);
    }
  };

  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Пациент найден</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <Description>
          Для продолжения работы подтвердите профиль пациента. При необходимости
          добавьте амбулаторную карту
        </Description>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Select
            title="Гражданство"
            required={true}
            placeholder="--"
            options={countries.map((country) => ({
              label: country.name,
              value: String(country.id),
              selected: `${country.id}` === `${selectedPatient?.countryId}`,
            }))}
            onSelect={() => { }}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Паспорт или иной ID"
            required={false}
            placeholder="xxxx xxxxxx"
            value={selectedPatient?.idPassport || ""}
            errorMessage="Пример: 1234 567890"
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Адрес"
            required={true}
            placeholder="--"
            value={selectedPatient?.address || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Фамилия"
            required={false}
            placeholder="--"
            value={selectedPatient?.lastName || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>
        <StyledInputContainer2>
          <Input
            title="Имя"
            required={true}
            placeholder="--"
            value={selectedPatient?.firstName || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Отчество"
            placeholder="--"
            value={selectedPatient?.patronymic || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Datepicker
            required={true}
            title="Дата рождения"
            placeholder="--"
            value={selectedPatient?.birthdate || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer2>
        {GENDERS.map((gender) => (
          <StyledInputContainer2 key={gender.code}>
            <Radio
              title={gender.code === GENDER_MALE ? "Пол" : undefined}
              selected={gender.code === selectedPatient?.gender}
              text={gender.name}
              onSelect={() => { }}
              tooltip={false}
              required={false}
              disabled={true}
            />
          </StyledInputContainer2>
        ))}
      </StyledRowContainer2>

      <StyledAmbulatoryCardContainer>
        <StyledRowContainer style={{ marginBottom: "16px" }}>
          <Title level="3">Амбулаторная карта</Title>
        </StyledRowContainer>

        <StyledRowContainer style={{ marginBottom: "8px" }}>
          <Description>
            Убедитесь, что выбранная амбулаторная карта зарегистрирована в
            учреждении направителя
          </Description>
        </StyledRowContainer>

        {selectedPatient.ambulatoryCards && selectedPatient.ambulatoryCards.length > 0 && (
          <StyledAmbulatoryCardsContainer>
            {selectedPatient.ambulatoryCards.map((ambulatoryCard, index) => (
              <>
                <StyledRowContainer key={index}>
                  <StyledInputContainer2>
                    <InputWithRadioToggle
                      title="Номер амбулаторной карты"
                      required={false}
                      placeholder=""
                      value={ambulatoryCard.number || ""}
                      onChange={(event) => { }}
                      disabled={true}
                      onToggle={() => {
                        dispatch(setPatientAmbulatoryCardToggled(index));
                        dispatch(setSelectedAmbulatoryCardNumber(ambulatoryCard.number || ""));
                        dispatch(setSelectedAmbulatoryCardOrganizationId(ambulatoryCard.organizationId || 0));
                        dispatch(setSelectedAmbulatoryCardOrganizationName(ambulatoryCard.organizationName || ""));
                      }}
                      index={index}
                      isToggled={ambulatoryCard.isSelected}
                    />
                  </StyledInputContainer2>
                  <StyledInputContainer2>
                    <Input
                      required={false}
                      title="УЗ"
                      placeholder=""
                      value={ambulatoryCard?.organizationName || ""}
                      onChange={(event) => { }}
                      disabled={true}
                    />
                  </StyledInputContainer2>
                </StyledRowContainer>
              </>
            ))}
            <StyledRowContainer2>
              <StyledInputContainer2>
                <Input
                  title="Номер амбулаторной карты"
                  required={true}
                  placeholder="--"
                  value={selectedAmbulatoryCard?.number || ""}
                  onChange={(event) => {
                    dispatch(setPatientAmbulatoryCardUntoggled());
                    dispatch(setSelectedAmbulatoryCardNumber(event.target.value));
                  }}
                  disabled={isSelectedPatientConfirmed}
                />
              </StyledInputContainer2>

              <StyledInputContainer2>
                <Select
                  title="УЗ"
                  placeholder="--"
                  required={true}
                  options={clinics.map((clinic) => ({
                    label: clinic.name_local_rus,
                    value: String(clinic.id),
                    selected: `${clinic.id}` === `${selectedAmbulatoryCard.organizationId}`,
                  }))}
                  onSelect={(option: { label: string, value: string }) => {
                    dispatch(setPatientAmbulatoryCardUntoggled());
                    dispatch(setSelectedAmbulatoryCardOrganizationId(Number(option.value)));
                    dispatch(setSelectedAmbulatoryCardOrganizationName(option.label));
                  }}
                  disabled={isSelectedPatientConfirmed}
                />
              </StyledInputContainer2>
            </StyledRowContainer2>
          </StyledAmbulatoryCardsContainer>
        )}
      </StyledAmbulatoryCardContainer>

      {!isSelectedPatientConfirmed && (
        <StyledRowContainer>
          <Button
            type="PRIMARY"
            text="Подтвердить"
            onClick={() => {
              dispatch(setSelectedPatientConfirmed(true));
            }}
          />
        </StyledRowContainer>
      )}
      {Boolean(isSelectedPatientConfirmed) && (
        <StyledRowContainer>
          <Button
            type="SECONDARY"
            text="Редактировать"
            onClick={() => {
              dispatch(setSelectedPatientConfirmed(false));
            }}
          />
        </StyledRowContainer>
      )}

    </StyledContainer>
  )
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledInputContainer2 = styled.div`
  width: 50%;
`;

const StyledAmbulatoryCardContainer = styled.div``;

const StyledRowContainer2 = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 16px;
`;

const StyledAmbulatoryCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
