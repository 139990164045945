import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";

export type CurrentStepState = {
  code: string | null;
  name: string | null;
};

const initialState: CurrentStepState = {
  code: "RESEARCH_TYPE",
  name: null,
};

export const currentStepSlice = createSlice({
  name: "currentStep",
  initialState,
  reducers: {
    resetCurrentStep: () => initialState,

    setCurrentStep: (
      state,
      action: PayloadAction<{ code: string; name: string }>
    ) => {
      state.code = action.payload.code;
      state.name = action.payload.name;
    },
  },
});

export const { setCurrentStep, resetCurrentStep } = currentStepSlice.actions;

export const currentStepReducer = currentStepSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const currentStepSelector = createSelector(
  getState,
  (state) => state.currentStep
);
