export const GENDER_MALE = "Male";
export const GENDER_FEMALE = "Female";

export const GENDERS = [
  {
    code: GENDER_MALE,
    name: "Мужской",
  },
  {
    code: GENDER_FEMALE,
    name: "Женский",
  },
] as const;

export type GenderCode = typeof GENDERS[number]['code'];

export function getGenderName(code: GenderCode | null): string | undefined {
  if (!code) return undefined;
  const gender = GENDERS.find(g => g.code === code);
  return gender ? gender.name : undefined;
}