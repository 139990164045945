// BIO MATERIAL SOURCE

export const SURGERY = "surgery";
export const BIOPSY = "biopsy";

export const BIO_MATERIAL_SOURCE = [
  {
    code: SURGERY,
    name: "Операционный",
  },
  {
    code: BIOPSY,
    name: "Биопсия",
  },
] as const;

export type BioMaterilaSourceCode = typeof BIO_MATERIAL_SOURCE[number]['code'];

export function getBioMaterialSourceName(code: BioMaterilaSourceCode | null): string | undefined {
  if (!code) return undefined;
  const source = BIO_MATERIAL_SOURCE.find(s => s.code === code);
  return source ? source.name : undefined;
}

// METHOD OF ACQUISITION

export const SURGICAL_BIOPSY = 'surgical_biopsy';
export const SURGICAL_MATERIAL = 'surgical_material';
export const SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS = 'spontaneously_separated_tissue_fragments';
export const ENDOSCOPIC_BIOPSY = 'endoscopic_biopsy';
export const PUNCTURE_BIOPSY = 'puncture_biopsy';
export const ASPIRATION_BIOPSY = 'aspiration_biopsy';
export const MICISIONAL_BIOPSY = 'micisional_biopsy';


export const METHOD_OF_ACQUISITION = [
  {
    code: SURGICAL_BIOPSY,
    name: "Операционная биопсия",
  },
  {
    code: SURGICAL_MATERIAL,
    name: "Операционный материал",
  },
  {
    code: SPONTANEOUSLY_SEPARATED_TISSUE_FRAGMENTS,
    name: "Самопроизвольно отделившиеся фрагменты тканей",
  },
  {
    code: ENDOSCOPIC_BIOPSY,
    name: "Эндоскопическая биопсия",
  },
  {
    code: PUNCTURE_BIOPSY,
    name: "Пункционная биопсия",
  },
  {
    code: ASPIRATION_BIOPSY,
    name: "Аспирационная биопсия",
  },
  {
    code: MICISIONAL_BIOPSY,
    name: "Инцизионная биопсия",
  }
] as const;

export type MethodOfAcquisitionCode = typeof METHOD_OF_ACQUISITION[number]['code'];

export function getMethodOfAcquisitionName(code: MethodOfAcquisitionCode | null): string | undefined {
  if (!code) return undefined;
  const source = METHOD_OF_ACQUISITION.find(s => s.code === code);
  return source ? source.name : undefined;
}

// BIO SAMPLE TYPE
export const PRIMARY = 'primary';
export const METASTASIS = 'metastasis';


export const BIO_SAMPLE_TYPE = [
  {
    code: PRIMARY,
    name: 'Первичная опухоль',
  },
  {
    code: METASTASIS,
    name: 'Метастаз',
  },
] as const;

export type BioSampleTypeCode = typeof BIO_SAMPLE_TYPE[number]['code'];

export function getBioSampleTypeName(code: BioSampleTypeCode | null): string | undefined {
  if (!code) return undefined;
  const source = BIO_SAMPLE_TYPE.find(s => s.code === code);
  return source ? source.name : undefined;
}