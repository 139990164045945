import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Steps } from "../../../../componentsNew/Steps";
import { StepsType, Step as StepType } from "../../../../componentsNew/Steps/types/Step";

import { StepStateChecker } from "../../utils/StepStateChecker";

import { currentStepSelector, setCurrentStep } from "../../redux/currentStep";
import { researchTypeSelector } from "../../redux/researchType";
import { steps } from "../..";
import { laboratorySelector } from "../../redux/laboratory";
import { selectedPatientSelector, selectedPatientConfirmedSelector } from "../../redux/patient";
import {
  dateSelector as referrerDateSelector,
  selectedDoctorSelector,
} from "../../redux/referrer";
import { clinicalDataSelector } from "../../redux/clinicalData";
import { bioMaterialRegistrationSelector } from "../../redux/biomaterialRegistration";

export const CreateReferralPageNewSteps = () => {
  const dispatch = useDispatch();

  const currentStep = useSelector(currentStepSelector);

  const researchType = useSelector(researchTypeSelector);

  const selectedLaboratory = useSelector(laboratorySelector);

  const selectedPatient = useSelector(selectedPatientSelector);
  const isSelectedPatientConfirmed = useSelector(selectedPatientConfirmedSelector);

  const selectedDoctor = useSelector(selectedDoctorSelector);

  const date = useSelector(referrerDateSelector);

  const clinicalData = useSelector(clinicalDataSelector);
  const bioMaterialRegistrationData = useSelector(bioMaterialRegistrationSelector);

  const stepsWithState: Array<StepType> = [
    {
      ...steps[0],
      state: StepStateChecker.getResearchTypeStepState({
        currentStep,
        researchType,
      }),
    },
    {
      ...steps[1],
      state: StepStateChecker.getLaboratoryStepState({
        currentStep,
        selectedLaboratory,
      }),
    },
    {
      ...steps[2],
      state: StepStateChecker.getPatientStepState({
        currentStep,
        selectedPatient,
        isSelectedPatientConfirmed,
      }),
    },
    {
      ...steps[3],
      state: StepStateChecker.getReferrerStepState({
        currentStep,
        selectedDoctor,
        date,
      }),
    },
    {
      ...steps[4],
      state: StepStateChecker.getClinicalDataStepState({ currentStep, clinicalData }),
    },
    {
      ...steps[5],
      state: StepStateChecker.getBiomaterialRegistrationStepState({
        currentStep,
        bioMaterialRegistrationData,
      }),
    },
    {
      ...steps[6],
      state: StepStateChecker.getMarkersStepState({ currentStep }),
    },
  ];

  const onStepChange = (stepCode: string) => {
    const step = stepsWithState.find((step) => step.code === stepCode);

    if (step) {
      dispatch(
        setCurrentStep({
          code: stepCode,
          name: step.name,
        })
      );
    }
  };

  return (
    <StyledStepsContainer>
      <Steps
        type={StepsType.CIRCLE_WITH_NUMBER}
        steps={stepsWithState}
        onChange={onStepChange}
      />
    </StyledStepsContainer>
  );
};

const StyledStepsContainer = styled.div`
  padding: 0 20px;

  margin-bottom: 32px;

  position: sticky;
  top: 0;

  background-color: white;
  z-index: 2;
`;
