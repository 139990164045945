import styled from "styled-components";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { Radio } from "../../../../../../componentsNew/Radio";
import { Select } from "../../../../../../componentsNew/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedPatientSelector,
  foreignSearchPassportOrIdSelector,
  selectedPatientConfirmedSelector,
  setSelectedPatientAddress,
  setSelectedPatientBirthdate,
  setSelectedPatientFirstName,
  setSelectedPatientLastName,
  setSelectedPatientPatronymic,
  setSelectedPatientGender,
  setSelectedPatientConfirmed,
  selectedAmbulatoryCardSelector,
  setSelectedAmbulatoryCardNumber,
  setSelectedAmbulatoryCardOrganizationId,
  setSelectedAmbulatoryCardOrganizationName,
  setSelectedPatientCountry,
  setSelectedPatientIdPassport,
} from "../../../../redux/patient";
import { Description } from "../../../../../../componentsNew/Description";
import { useHttpClient } from "../../../../../../hooks/useHttpClient";
import { useEffect, useState } from "react";
import { ClinicsService } from "../../../../../../services/Clinics";
import { CountriesService } from "../../../../../../services/Countries";

import { Button } from "../../../../../../componentsNew/Button";
import { GENDERS, GENDER_MALE, GenderCode } from "../../../../../../constants/genders";

export const NewForeignProfile = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();
  const [clinics, setClinics] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  
  const foreignSearchPassportOrId = useSelector(foreignSearchPassportOrIdSelector);

  const selectedPatient = useSelector(selectedPatientSelector);
  const selectedAmbulatoryCard = useSelector(selectedAmbulatoryCardSelector);
  const isSelectedPatientConfirmed = useSelector(selectedPatientConfirmedSelector);

  const effect = async () => {
    const clinicsService = new ClinicsService(httpClient);
    const _clinics = await clinicsService.searchClinics();

    const countriesService = new CountriesService(httpClient)
    const _countries = await countriesService.getCountries();

    if (_clinics?.length) {
      setClinics(_clinics);
    }

    if (_countries?.length) {
      setCountries(_countries);
    }

    dispatch(setSelectedPatientIdPassport(foreignSearchPassportOrId || ""));
  };

  useEffect(() => {
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSelectedPatientFormFilled = Boolean(
    selectedPatient?.birthdate &&
    selectedPatient?.firstName &&
    selectedPatient?.lastName &&
    selectedPatient?.address &&
    selectedPatient?.idPassport &&
    selectedPatient?.gender &&
    selectedAmbulatoryCard?.number &&
    selectedAmbulatoryCard?.organizationId &&
    selectedAmbulatoryCard?.organizationName
  );

  const onCountrySelect = (option: { label: string; value: string }) => {
    dispatch(setSelectedPatientCountry(Number(option.value)));
  };

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Совпадений не найдено. Создайте профиль</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Select
            title="Гражданство"
            required={true}
            placeholder="--"
            options={countries.map((country) => ({
              label: country.name,
              value: String(country.id),
              selected: `${country.id}` === `${selectedPatient?.countryId}`,
            }))}
            onSelect={onCountrySelect}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Паспорт или иной ID"
            required={false}
            placeholder="xxxx xxxxxx"
            value={selectedPatient?.idPassport || ""}
            errorMessage="Пример: 1234 567890"
            onChange={(event) => {}}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Адрес"
            required={true}
            placeholder="--"
            value={selectedPatient?.address || ""}
            onChange={(event) => {
              dispatch(setSelectedPatientAddress(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Фамилия"
            required={true}
            placeholder="--"
            value={selectedPatient?.lastName || ""}
            onChange={(event) => { 
              dispatch(setSelectedPatientLastName(event.target.value));
             }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer2>
        <StyledInputContainer2>
          <Input
            title="Имя"
            required={true}
            placeholder="--"
            value={selectedPatient?.firstName || ""}
            onChange={(event) => { 
              dispatch(setSelectedPatientFirstName(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Отчество"
            placeholder="--"
            value={selectedPatient?.patronymic || ""}
            onChange={(event) => { 
              dispatch(setSelectedPatientPatronymic(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Datepicker
            required={true}
            title="Дата рождения"
            placeholder="--"
            value={selectedPatient?.birthdate || ""}
            onChange={(event) => { 
              dispatch(setSelectedPatientBirthdate(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer2>
        {GENDERS.map((gender) => (
          <StyledInputContainer2 key={gender.code}>
            <Radio
              title={gender.code === GENDER_MALE ? "Пол" : undefined}
              selected={gender.code === selectedPatient?.gender}
              text={gender.name}
              onSelect={() => {
                dispatch(setSelectedPatientGender(gender.code as GenderCode));
              }}
              tooltip={false}
              required={
                gender.code === GENDER_MALE ? true : false
              }
              disabled={isSelectedPatientConfirmed}
            />
          </StyledInputContainer2>
        ))}
      </StyledRowContainer2>

      <StyledAmbulatoryCardContainer>
        <StyledRowContainer style={{ marginBottom: "16px" }}>
          <Title level="3">Амбулаторная карта</Title>
        </StyledRowContainer>

        <StyledRowContainer style={{ marginBottom: "8px" }}>
          <Description>
            Убедитесь, что выбранная амбулаторная карта зарегистрирована в
            учреждении направителя
          </Description>
        </StyledRowContainer>

        <StyledRowContainer2>
          <StyledInputContainer2>
            <Input
              title="Номер амбулаторной карты"
              required={true}
              placeholder="--"
              value={selectedAmbulatoryCard?.number || ""}
              onChange={(event) => {
                dispatch(setSelectedAmbulatoryCardNumber(event.target.value));
              }}
              disabled={isSelectedPatientConfirmed}
            />
          </StyledInputContainer2>

          <StyledInputContainer2>
            <Select
              title="УЗ"
              placeholder="--"
              required={true}
              options={clinics.map((clinic) => ({
                label: clinic.name_local_rus,
                value: String(clinic.id),
                selected: `${clinic.id}` === `${selectedAmbulatoryCard.organizationId}`,
              }))}
              onSelect={(option: { label: string, value: string}) => {
                dispatch(setSelectedAmbulatoryCardOrganizationId(Number(option.value)));
                dispatch(setSelectedAmbulatoryCardOrganizationName(option.label));
              }}
              disabled={isSelectedPatientConfirmed}
            />
          </StyledInputContainer2>
        </StyledRowContainer2>
      </StyledAmbulatoryCardContainer>

      {Boolean(!isSelectedPatientConfirmed) && (
        <StyledRowContainer>
          <Button
            type="PRIMARY"
            text="Подтвердить"
            onClick={() => {
              dispatch(setSelectedPatientConfirmed(true));
            }}
            disabled={!isSelectedPatientFormFilled}
          />
        </StyledRowContainer>
      )}
      {Boolean(isSelectedPatientConfirmed) && (
        <StyledRowContainer>
          <Button
            type="SECONDARY"
            text="Редактировать"
            onClick={() => {
              dispatch(setSelectedPatientConfirmed(false));
            }}
          />
        </StyledRowContainer>
      )}

    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledInputContainer2 = styled.div`
  width: 50%;
`;

const StyledRowContainer2 = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 16px;
`;

const StyledAmbulatoryCardContainer = styled.div``;
