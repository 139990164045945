export const Colors = {
  RED: {
    500: "#FE3546",
  },
  ORANGE: {
    400: "#FFD60A",
  },

  GREEN: {
    500: "#0ed962",
  },

  MONOCHROM: {
    50: "#F7F8F8",
    100: "#EDEEF1",
    200: "#D8DBDF",
    300: "#B5BAC4",
    400: "#8D94A3",
    500: "#6F7788",
    600: "#5C6373",
    700: "#494F5B",
    800: "#3F434D",
    900: "#373A43",
    950: "#25272C",
  },

  BRAND: {
    50: "#EEF3FF",
    100: "#D9E4FF",
    200: "#BCD1FF",
    300: "#8EB4FF",
    400: "#598BFF",
    500: "#3E6AFF",
    600: "#1B3EF5",
    700: "#142BE1",
    800: "#142BE1",
    900: "#19258F",
    950: "#141957",
  },

  OTHER: {
    white: '#FFFFFF',
  },

  GRAY: {
    100: '#F7F7F8',
    200: '#CECECE',
    300: '#DDDEDF',
    400: '#96999C',
    500: '#777777',
    600: '#666666',
  },
};
