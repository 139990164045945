import { StepState } from "../../../componentsNew/Steps/types/Step";
import { PatientState } from "../redux/patient";
import { ReferrerState } from "../redux/referrer";
import { ClinicalDataState } from "../redux/clinicalData";
import { BiomaterialRegistrationState } from "../redux/biomaterialRegistration";

export class StepStateChecker {
  static getResearchTypeStepState({
    currentStep,
    researchType,
  }: {
    currentStep: { code: string | null; name: string | null };
    researchType: { code: string | null; name: string | null };
  }) {
    if (currentStep.code === "RESEARCH_TYPE") {
      return StepState.SELECTED;
    }

    if (researchType) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getLaboratoryStepState({
    currentStep,
    selectedLaboratory,
  }: {
    currentStep: { code: string | null; name: string | null };
    selectedLaboratory: { id: number | null; name: string | null } | null;
  }) {
    if (currentStep.code === "LABORATORY") {
      return StepState.SELECTED;
    }

    if (selectedLaboratory?.id) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getPatientStepState({
    currentStep,
    selectedPatient,
    isSelectedPatientConfirmed,
  }: {
    currentStep: { code: string | null; name: string | null };
    selectedPatient: PatientState["selectedPatient"];
    isSelectedPatientConfirmed: PatientState["selectedPatientConfirmed"];
  }) {
    if (currentStep.code === "PATIENT") {
      return StepState.SELECTED;
    }

    if (selectedPatient && isSelectedPatientConfirmed) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getReferrerStepState({
    currentStep,
    selectedDoctor,
    date,
  }: {
    currentStep: { code: string | null; name: string | null };
    selectedDoctor: ReferrerState["selectedDoctor"];
    date: ReferrerState["date"];
  }) {
    if (currentStep.code === "REFERRER") {
      return StepState.SELECTED;
    }

    if ((selectedDoctor.ulid || (selectedDoctor.userProfile.firstName && selectedDoctor.userProfile.lastName && selectedDoctor.userProfile.middleName)) && date) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getClinicalDataStepState({
    currentStep,
    clinicalData,
  }: {
    currentStep: { code: string | null; name: string | null };
    clinicalData: ClinicalDataState;
  }) {
    if (currentStep.code === "CLINICAL_DATA") {
      return StepState.SELECTED;
    }
    
    const isClinicalDataComplete = (data: ClinicalDataState): boolean => {
      for (const key in data) {
        const value = (data as any)[key];
        if (
          value === null || 
          value === undefined || 
          (Array.isArray(value) && value.length === 0)
        ) {
          return false;
        }
      }
      return true;
    };

    if (isClinicalDataComplete(clinicalData)) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getBiomaterialRegistrationStepState({
    currentStep,
    bioMaterialRegistrationData,
  }: {
    currentStep: { code: string | null; name: string | null };
    bioMaterialRegistrationData: BiomaterialRegistrationState;
  }) {
    if (currentStep.code === "BIOMATERIAL_REGISTRATION") {
      return StepState.SELECTED;
    }

    const isBioMaterialRegistrationComplete = (data: BiomaterialRegistrationState): boolean => {
      for (const key in data) {
        const value = (data as any)[key];
        if (
          value === null || 
          value === undefined || 
          (Array.isArray(value) && value.length === 0)
        ) {
          return false;
        }
      }
      return true;
    };

    if (isBioMaterialRegistrationComplete(bioMaterialRegistrationData)) {
      return StepState.WITH_SUCCESS;
    }

    return StepState.DISABLED;
  }

  static getMarkersStepState({
    currentStep,
  }: {
    currentStep: { code: string | null; name: string | null };
  }) {
    if (currentStep.code === "MARKERS") {
      return StepState.SELECTED;
    }

    return StepState.DISABLED;
  }
}
