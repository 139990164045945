import { useDispatch, useSelector } from "react-redux";
import { useHttpClient } from "../../../hooks/useHttpClient";
import {
  ReferralsService,
} from "../../../services/Referrals";
import { laboratorySelector } from "../redux/laboratory";
import { selectedPatientSelector, selectedAmbulatoryCardSelector } from "../redux/patient";
import {
  dateSelector as referrerDateSelector,
  selectedDoctorSelector,
} from "../redux/referrer";
import {
  additionalClinicalDataSelector,
  clinicalDiagnosisSelector,
  mSelector,
  mSubSelector,
  nSelector,
  nSubSelector,
  previousResultsSelector,
  selectedDiagnosisSelector,
  selectedLocalizationSelector,
  stageSelector,
  treatmentSelector,
  tSelector,
  tSubSelector,
} from "../redux/clinicalData";
import {
  dateSelector as biomaterialRegistrationDateSelector,
} from "../redux/biomaterialRegistration";
import { markersSelector } from "../redux/markers";
import {
  setCreatedReferralNumber,
  setCreateSuccessModalActive,
  setCreateReferralErrors,
} from "../redux/common";

export const useCreate = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const laboratory = useSelector(laboratorySelector);

  const selectedPatient = useSelector(selectedPatientSelector);

  const selectedAmbulatoryCard = useSelector(selectedAmbulatoryCardSelector);

  const selectedDoctor = useSelector(selectedDoctorSelector);

  const referrerDate = useSelector(referrerDateSelector);

  const selectedLocalization = useSelector(selectedLocalizationSelector);

  const t = useSelector(tSelector);
  const tSub = useSelector(tSubSelector);

  const n = useSelector(nSelector);
  const nSub = useSelector(nSubSelector);

  const m = useSelector(mSelector);
  const mSub = useSelector(mSubSelector);

  const stage = useSelector(stageSelector);

  const additionalClinicalData = useSelector(additionalClinicalDataSelector);

  const previousResults = useSelector(previousResultsSelector);

  const treatment = useSelector(treatmentSelector);

  const referralsService = new ReferralsService(httpClient);

  const selectedDiagnosis = useSelector(selectedDiagnosisSelector);

  const clinicalDiagnosis = useSelector(clinicalDiagnosisSelector);

  const biomaterialRegistrationDate = useSelector(
    biomaterialRegistrationDateSelector
  );

  const markers = useSelector(markersSelector);

  const createIhcReferral = async () => {
    console.log('createIhcReferral')

    if (!laboratory?.id) {
      console.log('CreateIhcReferral: laboratory not found')
      return;
    }

    if (!selectedPatient) {
      console.log('CreateIhcReferral: patient not found')
      return;
    }

    if (!selectedAmbulatoryCard) {
      console.log('CreateIhcReferral: ambulatory card not found')
      return;
    }

    if (!selectedDoctor) {
      console.log('CreateIhcReferral: doctor not found')
      return;
    }

    const result = await referralsService.createIhcReferral({
      laboratory: laboratory.id,
      patient: {
        ulid: selectedPatient?.ulid || "",
        country: selectedPatient?.countryId || 112,
        id_passport: selectedPatient?.idPassport || "",
        address: selectedPatient?.address || "",
        gender: selectedPatient?.gender || "",
        last_name: selectedPatient?.lastName || "",
        first_name: selectedPatient?.firstName || "",
        middle_name: selectedPatient?.patronymic || "",
        oms_number: selectedPatient?.oms || "",
        snils_number: selectedPatient?.snils || "",
        date_of_birth: selectedPatient?.birthdate || "",
      },
      ambulatory_card: {
        organization_id: selectedAmbulatoryCard.organizationId as number,
        number: selectedAmbulatoryCard.number as string,
      },
      doctor_referral: {
        ulid: selectedDoctor.ulid,
        user_profile: {
          last_name: selectedDoctor.userProfile.lastName || "",
          first_name: selectedDoctor.userProfile.firstName || "",
          middle_name: selectedDoctor.userProfile.middleName || "",
        }
      },
      referral_date: referrerDate as string,
      diagnosis: {
        localization_id: selectedLocalization?.value
          ? parseInt(selectedLocalization?.value, 10)
          : 0,
        icd_10: selectedDiagnosis?.value
          ? parseInt(selectedDiagnosis?.value, 10)
          : 0,
        description: clinicalDiagnosis || "",
        t_category: t?.value || "",
        t_sub: tSub?.value || "",
        n_category: n?.value || "",
        n_sub: nSub?.value || "",
        m_category: m?.value || "",
        m_sub: mSub?.value || "",
        tnm_non_applicable: false,
        tnm_unknown: false,
        stage: stage?.value || "",
        stage_non_applicable: false,
        stage_unknown: false,
        additional_info: additionalClinicalData as string,
        additional_info_non_applicable: false,
        additional_info_unknown: false,
        previous_analysis_results: previousResults as string,
        previous_analysis_results_non_applicable: false,
        previous_analysis_results_unknown: false,
        pre_ambulatory_care: treatment as string,
        pre_ambulatory_care_non_applicable: false,
        pre_ambulatory_care_unknown: false,
      },
      bio: {
        organization: 4,
        bio_source: "surgery",
        method_of_acquisition: "surgical_biopsy",
        sample_type: "metastasis",
        date_of_acquisition: biomaterialRegistrationDate as string,
        blocks: [
          {
            organ: "Lung",
            cypher: "123456-9/25",
            slides: [{
              organ: "Lung",
              cypher: "123456-7/25"
            },
              {
                organ: "Lung",
                cypher: "123458-9/25"
              }
            ]
          },
          {
            organ: "Lung",
            cypher: "123476-9/25",
            slides: [{
              organ: "Lung",
              cypher: "123476-7/25"
            },
              {
                organ: "Lung",
                cypher: "123478-9/25"
              }
            ]
          }
    ]
    },
      markers: markers.map((marker) => marker.id),
    });

    if (result?.success) {
      dispatch(setCreateSuccessModalActive(true));
      dispatch(setCreatedReferralNumber(result?.referral));
    } else {
      dispatch(setCreateReferralErrors(result));
    };
  };

  return {
    createIhcReferral,
  };
};
