import { FC } from 'react';
import CodeTableCell from './CodeTableCell';
import { TCustomRow } from '../styled';

type PropsType = {
  row: any;
  index: number;
  block?: any;
  slide?: any;
};

const SlideRow: FC<PropsType> = (props) => {
  const {
    row,
    index,
    block,
    slide,
  } = props;

  let organTitle = `${index + 1}. ${row.organ}`;

  return (
    <TCustomRow key={row}>
      <td className='wrapWords maxWidth20'>{organTitle}</td>
      <CodeTableCell block={block} color={'lightViolet'} />
      <CodeTableCell slide={slide} color={'lightOrange'} />
    </TCustomRow>
  );
};

export default SlideRow;
