import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';

export const TReadOnlyInput = styled.div<{ width?: string }>`
  display: inline-block;
  width: ${({ width }) => (width ? width : '100%')};
  margin-bottom: 16px;
`;

export const TLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.MONOCHROM[950]};
  margin-bottom: 4px;
`;

export const TValue = styled.div`
  border: 1px solid ${Colors.MONOCHROM[200]};
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.MONOCHROM[600]};
  padding: 12px 16px;
`;
