import { createGlobalStyle, css } from 'styled-components';
import datePickerStyles from 'react-datepicker/dist/react-datepicker.css';

// @import ${datePickerStyles};
const global = css`
  
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
    padding: 0;
  }

  * {
    font-family: 'Inter', sans-serif;
  }

  body {
    font-size: 16px;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .__react_component_tooltip.show {
    opacity: 1 !important;
  }
`;

export default createGlobalStyle`
  ${global}
  ${datePickerStyles}
`;
