import styled from "styled-components";
import { Colors } from "../../styles/themeNew";
import { Icon } from "../Icon";
import Popup from "../../containers/CreateReferralPageNew/components/ClinicalData/components/TnmClassification/Popup";
import { useState } from "react";

type Props = {
  title?: string;
  required?: boolean;
  placeholder?: string;
  value: string;
  infoicon?: boolean;
  popupText?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const Textarea = (props: Props) => {

  const [isVisible, setIsVisible] = useState(false);

  return (
    <StyledContainer>
      {props.title && (
        <StyledLabel htmlFor="textarea">
          {props.title}{" "}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
          {props.infoicon && (
            <StyledContainerInfo
              onMouseEnter={() => setIsVisible(true)}
              onMouseLeave={() => setIsVisible(false)} 
            >
              <Icon type={isVisible ? "info-colored" : "info"} />
              <Popup visible={isVisible} position="right">{props.popupText}</Popup>
            </StyledContainerInfo>
          )}
        </StyledLabel>
      )}

      <StyledMainContainer>
        <StyledTextarea
          id="textarea"
          name="textarea_name"
          placeholder={props?.placeholder || ""}
          value={props.value}
          onChange={props.onChange}
        />
      </StyledMainContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledMainContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  gap: 4px;
`;

const StyledTextarea = styled.textarea`
  height: 100%;
  margin: 0;
  padding: 16px 16px;
  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background: transparent;
  box-sizing: border-box;
  width: 100%;
  color: ${Colors.MONOCHROM[950]};
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: ${Colors.MONOCHROM[400]};
  }
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;

const StyledContainerInfo = styled.div`
  position: relative;
  padding-top: 4px;

  :hover {
    cursor: pointer;
  }
`;
