import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../../store";
import { Patient } from "../../../store/patient/model";
import { ApiPatient } from "../../../services/Patients";
import { GenderCode } from "../../../constants/genders";

export type PatientState = {
  citizenshipCode: string | null;

  searchProcessLoading: boolean;
  searchProcessInitiated: boolean;
  searchProcessResult: any | null;  // TODO: check if it's needed

  rfSearchFirstName: string | null;
  rfSearchLastName: string | null;
  rfSearchPatronymic: string | null;
  rfSearchBirthdate: string | null;

  foreignSearchPassportOrId: string | null;

  patientsToSelect: [{
    isSelected: boolean;
    ulid: string | null;
    idPassport: string | null;
    address: string | null;
    countryId: number | null;
    countryName: string | null;
    countryAlphaCode: string | null;
    gender: GenderCode | null;
    lastName: string | null;
    firstName: string | null;
    patronymic: string | null;
    oms: string | null;
    snils: string | null;
    birthdate: string | null;
    fullName: string | null;
    ambulatoryCards: [{
      id: number | null;
      isSelected: boolean;
      number: string | null;
      organizationId: number | null;
      organizationName: string | null;
    }] | null;
  }] | null;

  selectedPatient: {
    ulid: string | null;
    idPassport: string | null;
    address: string | null;
    countryId: number | null;
    countryName: string | null;
    countryAlphaCode: string | null;
    gender: GenderCode | null;
    lastName: string | null;
    firstName: string | null;
    patronymic: string | null;
    oms: string | null;
    snils: string | null;
    birthdate: string | null;
    fullName: string | null;
    ambulatoryCards: [{
      id: number | null;
      isSelected: boolean;
      number: string | null;
      organizationId: number | null;
      organizationName: string | null;
    }] | null;
  };

  selectedPatientAmbulatoryCards: [
    {
      id: number | null;
      isSelected: boolean;
      number: string | null;
      organizationId: number | null;
      organizationName: string | null;
    }
  ] | null;

  selectedAmbulatoryCard: {
    number: string | null;
    organizationId: number | null;
    organizationName: string | null;
    customrOrganizationName: string | null;
  };

  selectedPatientConfirmed: boolean;
};

const initialState: PatientState = {
  citizenshipCode: null,

  searchProcessLoading: false,
  searchProcessInitiated: false,
  searchProcessResult: null,

  rfSearchFirstName: null,
  rfSearchLastName: null,
  rfSearchPatronymic: null,
  rfSearchBirthdate: null,

  foreignSearchPassportOrId: null,

  patientsToSelect: null,

  selectedPatientConfirmed: false,

  selectedPatient: {
    ulid: null,
    idPassport: null,
    address: null,
    countryId: null,
    countryName: null,
    countryAlphaCode: null,
    gender: null,
    lastName: null,
    firstName: null,
    patronymic: null,
    oms: null,
    snils: null,
    birthdate: null,
    fullName: null,
    ambulatoryCards: null,
  },

  selectedPatientAmbulatoryCards: null,

  selectedAmbulatoryCard: {
    number: null,
    organizationId: null,
    organizationName: null,
    customrOrganizationName: null
  }
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    resetPatient: () => initialState,

    setCitizenshipCode: (
      state,
      action: PayloadAction<{ citizenshipCode: string }>
    ) => {
      state.citizenshipCode = action.payload.citizenshipCode;
    },

    setRfSearchFirstName: (state, action: PayloadAction<string>) => {
      state.rfSearchFirstName = action.payload;
    },

    setRfSearchLastName: (state, action: PayloadAction<string>) => {
      state.rfSearchLastName = action.payload;
    },

    setRfSearchPatronymic: (state, action: PayloadAction<string>) => {
      state.rfSearchPatronymic = action.payload;
    },

    setRfSearchBirthdate: (state, action: PayloadAction<string>) => {
      state.rfSearchBirthdate = action.payload;
    },

    setForeignSearchPassportOrId: (state, action: PayloadAction<string>) => {
      state.foreignSearchPassportOrId = action.payload;
    },

    setSearchProcessLoading: (state, action: PayloadAction<boolean>) => {
      state.searchProcessLoading = action.payload;
    },

    setSearchProcessInitiated: (state, action: PayloadAction<boolean>) => {
      state.searchProcessInitiated = action.payload;
    },

    setSearchProcessResult: (
      state,
      action: PayloadAction<Array<Patient> | null>
    ) => {
      state.searchProcessResult = action.payload;
    },

    setPatientsToSelect: (state, action: PayloadAction<Array<ApiPatient>>) => {
      if (!action.payload) return;
      // @ts-ignore
      state.patientsToSelect = action.payload.map((patient, id) => ({
        isSelected: false,
        ulid: patient.ulid,
        idPassport: patient.id_passport,
        address: patient.address,
        countryId: patient.country,
        countryName: patient.country_name,
        countryAlphaCode: patient.country_alpha,
        gender: patient.gender,
        lastName: patient.last_name,
        firstName: patient.first_name,
        patronymic: patient.middle_name,
        oms: patient.oms_number,
        snils: patient.snils_number,
        birthdate: patient.date_of_birth,
        fullName: patient.full_name,
        ambulatoryCards: patient.ambulatory_cards.map((ambulatoryCard) => ({
          isSelected: false,
          number: ambulatoryCard.number,
          organizationId: ambulatoryCard.organization_id,
          organizationName: ambulatoryCard.organization
        }))
      }));
    },

    setPatientToSelectToggled: (state, action: PayloadAction<any>) => {
      // @ts-ignore
      state.patientsToSelect = state.patientsToSelect.map((patient, index) => ({
        ...patient,
        isSelected: index === action.payload,
      }));
    },

    setSelectedPatient: (state) => {
      const selected = state.patientsToSelect?.find(patient => patient.isSelected);
      if (selected) {
        state.selectedPatient = { ...selected };
      }
    },

    setPatientAmbulatoryCardToggled: (state, action: PayloadAction<number>) => {
      // @ts-ignore
      state.selectedPatient.ambulatoryCards = state.selectedPatient.ambulatoryCards.map((card, index) => ({
        ...card,
        isSelected: index === action.payload,
      }));
    },

    setPatientAmbulatoryCardUntoggled: (state) => {
      // @ts-ignore
      state.selectedPatient.ambulatoryCards = state.selectedPatient.ambulatoryCards.map((card, index) => ({
        ...card,
        isSelected: false,
      }));
    },

    setSelectedPatientUlid: (state, action: PayloadAction<string>) => {
      state.selectedPatient.ulid = action.payload;
    },

    setSelectedPatientCountry: (state, action: PayloadAction<number>) => {
      state.selectedPatient.countryId = action.payload;
    },

    setSelectedPatientLastName: (state, action: PayloadAction<string>) => {
      state.selectedPatient.lastName = action.payload;
    },

    setSelectedPatientFirstName: (state, action: PayloadAction<string>) => {
      state.selectedPatient.firstName = action.payload;
    },

    setSelectedPatientPatronymic: (state, action: PayloadAction<string>) => {
      state.selectedPatient.patronymic = action.payload;
    },
    setSelectedPatientGender: (state, action: PayloadAction<GenderCode>) => {
      state.selectedPatient.gender = action.payload;
    },

    setSelectedPatientBirthdate: (state, action: PayloadAction<string>) => {
      state.selectedPatient.birthdate = action.payload;
    },

    setSelectedPatientIdPassport: (state, action: PayloadAction<string>) => {
      state.selectedPatient.idPassport = action.payload;
    },

    setSelectedPatientAddress: (state, action: PayloadAction<string>) => {
      state.selectedPatient.address = action.payload;
    },

    setSelectedPatientOms: (state, action: PayloadAction<string>) => {
      state.selectedPatient.oms = action.payload;
    },

    setSelectedPatientSnils: (state, action: PayloadAction<string>) => {
      state.selectedPatient.snils = action.payload;
    },

    setSelectedPatientConfirmed: (state, action: PayloadAction<boolean>) => {
      state.selectedPatientConfirmed = action.payload;
    },

    setSelectedAmbulatoryCardNumber: (state, action: PayloadAction<string>) => {
      state.selectedAmbulatoryCard.number = action.payload;
    },

    setSelectedAmbulatoryCardOrganizationId: (state, action: PayloadAction<number>) => {
      state.selectedAmbulatoryCard.organizationId = action.payload;
    },

    setSelectedAmbulatoryCardOrganizationName: (state, action: PayloadAction<string>) => {
      state.selectedAmbulatoryCard.organizationName = action.payload;
    },

    resetSearch: () => initialState,

  },
});

export const {
  resetPatient,
  resetSearch,
  setPatientsToSelect,
  setPatientToSelectToggled,
  setCitizenshipCode,
  setSearchProcessLoading,
  setSearchProcessInitiated,
  setSearchProcessResult,
  setRfSearchFirstName,
  setRfSearchLastName,
  setRfSearchPatronymic,
  setRfSearchBirthdate,
  setForeignSearchPassportOrId,
  setSelectedPatient,
  setSelectedPatientUlid,
  setSelectedPatientCountry,
  setSelectedPatientConfirmed,
  setSelectedPatientLastName,
  setSelectedPatientFirstName,
  setSelectedPatientPatronymic,
  setSelectedPatientGender,
  setSelectedPatientBirthdate,
  setSelectedPatientIdPassport,
  setSelectedPatientAddress,
  setSelectedPatientOms,
  setSelectedPatientSnils,
  setSelectedAmbulatoryCardNumber,
  setSelectedAmbulatoryCardOrganizationId,
  setSelectedAmbulatoryCardOrganizationName,
  setPatientAmbulatoryCardToggled,
  setPatientAmbulatoryCardUntoggled,
} = patientSlice.actions;

export const patientReducer = patientSlice.reducer;

const getState = (state: IAppState) => state.createReferralPageNew;

export const selectedPatientAmbulatoryCardsSelector = createSelector(
  getState,
  (state) => state.patient.selectedPatientAmbulatoryCards
);

export const selectedPatientConfirmedSelector = createSelector(
  getState,
  (state) => state.patient.selectedPatientConfirmed
);

export const patientsToSelectSelector = createSelector(
  getState,
  (state) => state.patient.patientsToSelect
);

export const selectedPatientUlidSelector = createSelector(
  getState,
  (state) => state.patient.selectedPatient.ulid
);

export const selectedPatientSelector = createSelector(
  getState,
  (state) => state.patient.selectedPatient
);

export const rfSearchFirstNameSelector = createSelector(
  getState,
  (state) => state.patient.rfSearchFirstName
);

export const rfSearchLastNameSelector = createSelector(
  getState,
  (state) => state.patient.rfSearchLastName
);

export const rfSearchPatronymicSelector = createSelector(
  getState,
  (state) => state.patient.rfSearchPatronymic
);

export const rfSearchBirthdateSelector = createSelector(
  getState,
  (state) => state.patient.rfSearchBirthdate
);

export const foreignSearchPassportOrIdSelector = createSelector(
  getState,
  (state) => state.patient.foreignSearchPassportOrId
);

export const patientCitizenshipCodeSelector = createSelector(
  getState,
  (state) => state.patient.citizenshipCode
);

export const searchProcessLoadingSelector = createSelector(
  getState,
  (state) => state.patient.searchProcessLoading
);

export const searchProcessInitiatedSelector = createSelector(
  getState,
  (state) => state.patient.searchProcessInitiated
);

export const searchProcessResultSelector = createSelector(
  getState,
  (state) => state.patient.searchProcessResult
);

export const selectedAmbulatoryCardSelector = createSelector(
  getState,
  (state) => state.patient.selectedAmbulatoryCard
);
