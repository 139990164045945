import styled from 'styled-components';

interface TInfoBlockWrapper {
  title?: string;
  borderRadiusTopRight?: number;
  borderRadiusBottom?: number;
}

interface IDiv {
  borderRadiusTopRight?: number;
  borderRadiusBottom?: number;
}

export const TInfoTabsWrapper = styled.div<TInfoBlockWrapper>`
  position: relative;
  width: 100%;
`
export const TSpan = styled.span`
  padding: 8px 0 10px 24px;
  line-height: 32px;
  height: 38px;
  top: -56px;
  background-color: #FFFFFF;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  font-weight: 600;
  font-size: 20px;
  z-index:1;
  display: block;
`
export const TDiv = styled.div<IDiv>`
  display: block;
  margin-bottom: 10px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.white};
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
`

export const TSubTitle = styled.p`
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
  color: #6F7788
`;

export const Divider = styled.div`
  width: 95%;
  height: 1px;
  background-color: #D8DBDF;
  margin: 8px 0;
`