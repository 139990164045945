import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';

interface ITTextarea {
  error?: boolean | null;
  height?: number;
  bordered?: boolean;
  disabled?: boolean;
}

export const TTextareaWr = styled.div<ITTextarea>`
  width: 100%;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
  line-height: 18px;

  textarea {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
    box-sizing: border-box;
    word-wrap: break-word;
    display: inline-block;
    padding: 14px 16px;
    border-radius: 8px;
    width: 100%;
    height: ${({ height = 132 }) => height}px;
    border: 1px solid
      ${({ theme, bordered, error }) =>
        error
          ? theme.colors.alert.warning
          : bordered
          ? Colors.MONOCHROM[200]
          : theme.colors.white};
    color: ${Colors.MONOCHROM[600]};

    &:focus,
    &:focus-visible,
    &:hover:not(:disabled) {
      border-color: ${({ theme, error }) =>
        error ? theme.colors.alert.warning : Colors.BRAND[500]};
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray[200]};
      opacity: 1;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
    input[type='number'] {
    }
    :hover,
    :focus {
      -moz-appearance: number-input;
    }

    &:read-only:hover {
      cursor: default;
      border-color: ${({ theme }) => theme.colors.gray[400]};
    }
  }
`;

export const TLabel = styled.label`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${Colors.MONOCHROM[950]};
`;

export const TNotEditedTextArea = styled.div<{
  bordered?: boolean;
  error?: boolean;
  height?: number;
}>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.MONOCHROM[600]};
  border: 1px solid
    ${({ theme, bordered, error }) =>
      error
        ? theme.colors.alert.warning
        : bordered
        ? Colors.MONOCHROM[200]
        : theme.colors.white};
  box-sizing: border-box;
  word-wrap: break-word;
  display: inline-block;
  padding: 14px 16px;
  border-radius: 8px;
  width: 100%;
  min-height: ${({ height }) => (!!height ? `${height}px` : 'min-content')};
`;
