import React, {FC, useState} from "react";
import Input from '../../../components/shared/Input';
import { ISubBlock } from "../../../store/molecularProfiling/model";


interface Props {
  isEdit: boolean;

  subBlockList: ISubBlock[];
  saveData: (type: string, data: { description: string; implementer: string; tableFields: ISubBlock[] }) => void;
  hasChangesTrigger: (isChange: any) => void;

  [key: string]: any;
}

const optionsSingsViolation = [
  { value: 'crumbling', label: 'Крошение' },
  { value: 'uneven_coloring', label: 'Неравномерность окрашивания' },
  { value: 'thick_cut', label: 'Толстый срез' },
  { value: 'fuzzy_structures', label: 'Нечеткие структуры' },
  { value: 'none', label: 'Не выявлены' },
];

const getSingsViolationLabel = (value: string | undefined) => {
  return optionsSingsViolation.find((option) => option.value === value)?.label || "Неизвестно";
};

const CollapsibleForm: FC<Props> = ({ subBlockList }) => {
  const [openBlock, setOpenBlock] = useState<number | null>(null);

  const toggleBlock = (id: number) => {
    setOpenBlock(openBlock === id ? null : id);
  };

  return (
    <div className="max-w-lg mx-auto p-4">
      <h1 className="text-xl font-bold text-blue-600 mb-4">
        Характеристики блоков, взятых для исследования
      </h1>
      {subBlockList.map((block) => (
        <div key={block.id} className="border border-gray-300 rounded-lg mb-2">
          <button
            className="w-full text-left p-2 bg-gray-100 hover:bg-gray-200 font-semibold flex justify-between"
            onClick={() => toggleBlock(block.id ?? 0)}
          >
            {block.cypher}
            <span>{openBlock === block.id ? "▲" : "▼"}</span>
          </button>
          {openBlock === block.id && (
            <div className="p-4 bg-white">
              <div className="mb-2">
                <label className="font-semibold">Категория сложности:</label>
                <Input
                  type="text"
                  value={block.difficultyCategory}
                  className="border w-full p-1 rounded mt-1"
                  onChange={(event) => { }}
                />
              </div>
              <div className="mb-2">
                <label className="font-semibold">Сохранность упаковки:</label>
                <Input
                  type="text"
                  value={block.packagingSafety ? "Да" : "Нет"}
                  className="border w-full p-1 rounded mt-1"
                />
              </div>
              <div className="mb-2">
                <label className="font-semibold">Загрязненность образца:</label>
                <Input
                  type="text"
                  value={block.sampleContamination ? "Да" : "Нет"}
                  className="border w-full p-1 rounded mt-1"
                />
              </div>
              <div className="mb-2">
                <label className="font-semibold">Опухолевые клетки, %:</label>
                <Input
                  type="text"
                  value={block.cancerousCellPercentage ?? 0}
                  className="border w-full p-1 rounded mt-1"
                />
              </div>
              <div className="mb-2">
                <label className="font-semibold">
                  Признаки нарушения фиксации, проводки, окрашивания:
                </label>
                <Input
                  type="text"
                  value={getSingsViolationLabel(block.singsViolation)}
                  className="border w-full p-1 rounded mt-1"
                />
              </div>
              <div className="mb-2">
                <label className="font-semibold">
                  Доля некротизированной ткани в срезе, %:
                </label>
                <Input
                  type="text"
                  value={block.necroticTissue}
                  className="border w-full p-1 rounded mt-1"
                />
              </div>
              <div className="flex justify-end">
                <button className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600">
                  Редактировать
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CollapsibleForm;