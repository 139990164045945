import { useDispatch, useSelector } from "react-redux";
import { useHttpClient } from "../../../hooks/useHttpClient";
import { patientsToSelectSelector, setPatientsToSelect } from "../redux/patient";
import { PatientsService } from "../../../services/Patients";

export const usePatients = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const patients = useSelector(patientsToSelectSelector);

  const searchPatients = async (props: {
    lastName?: string;
    firstName?: string;
    middleName?: string;
    dateOfBirth?: string;
    excludeCountryAlpha?: string;
    countryAlpha?: string;
    passportId?: string;
  }) => {
    const patientsService = new PatientsService(httpClient);

    const _patients = await patientsService.searchPatients(props);

    dispatch(setPatientsToSelect(_patients));
  };

  return {
    patients,
    searchPatients,
  };
};
