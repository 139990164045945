import styled from 'styled-components';
import Input from "../../../components/shared/Input";
import Textarea from "../../../components/shared/Textarea";

export const TClinicalDataWr = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;


  input.text-input-class, textarea {
    color: ${({theme}) => theme.colors.gray[500]};
    /* margin-top: 0px; */
  }

  label {
    line-height: 20px;
  }


`
export const TRowWr = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
  height: 70px;
  padding: 0;
`

export const TCustomInput = styled(Input)<{ width?: string, background?: string }>`
  width: ${({width}) => width ? width : '100%'};
  padding-bottom: 16px;

  & :read-only {
    cursor: default;
  }

  & input {
    padding: 18px;
    background-color: ${({background}) => background ? background : '#fff'};

  }
`
export const TTextarea = styled(Textarea)`
  padding-bottom: 16px;
`
