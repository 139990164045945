import { FC } from 'react';
import { TLabel, TReadOnlyInput, TValue } from './styled';

export interface IReadOnlyInput {
  label?: string;
  width?: string;
  className?: string;
  placeholder?: string;
}

const ReadOnlyInput: FC<IReadOnlyInput> = ({
  className,
  label,
  placeholder,
  width,
  children,
}) => {
  return (
    <TReadOnlyInput width={width} className={className}>
      <TLabel>{label}</TLabel>
      <TValue>
        {!!children ? children : !!placeholder ? placeholder : 'Нет данных'}
      </TValue>
    </TReadOnlyInput>
  );
};

export default ReadOnlyInput;
