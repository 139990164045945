import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';


export const TWrapper = styled.div`
  width: 100%;
  font: 'Inter', sans-serif;
  //height: 100%;
  & .ousideElement {
    position: absolute;
    left: 5px;
    top:0;
    width: 10px;
    height: 10px;
    background-color: darkcyan;
  }
`;

const TCol = styled.div`
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  padding: 0 12px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  /* white-space: nowrap; */
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const TRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  box-sizing: border-box;
`;

export const THeaderRowWr = styled(TRow)<{headerColor?:string}>`
  background-color: ${({ headerColor }) => headerColor ? headerColor : 'transparent'};
  border: 1px solid #D8DBDF;

`;

export const THeaderColWr = styled(TCol)<{ columnWidth: string, position: string, margin: string, textColor?:string  }>`
  max-width: ${({ columnWidth }) => columnWidth};
  justify-content: ${({ position }) => position};
  margin: ${({ margin }) => margin};
  flex-grow: 1;
  font-weight: 700;
  color: ${({ textColor }) => textColor ? textColor : Colors.GRAY[400]};
`;

export const TRowWr = styled(TRow)<{bgColor?:string;}>`
  height: 54px;
  border: 1px solid #D8DBDF;
  border-top: none;

  background-color: ${({ theme, bgColor }) => bgColor ? bgColor : theme.colors.white};
  position: relative;
  cursor: pointer;
  
  &:hover{
    z-index: 2;
    background-color: ${Colors.MONOCHROM[50]};

  }
  
  &:hover div {
    color: ${Colors.MONOCHROM[950]};
  }
  
  &:hover span {
    color: ${Colors.MONOCHROM[950]};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const TRowColWr = styled(TCol)<{ columnWidth: string; position: string; margin: string; textColor?: string; }>`
  max-width: ${({ columnWidth }) => columnWidth};
  justify-content: ${({ position }) => position};
  margin: ${({ margin }) => margin};
  flex-wrap: wrap;
  font-weight: 500;
  color: ${({ textColor }) => textColor ? textColor : Colors.MONOCHROM[500]};

  &:last-child{
    color: ${Colors.MONOCHROM[600]};
  }
`;

