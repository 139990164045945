import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const TReactTooltip = styled(ReactTooltip)`
  width: 318px;
  max-width: 318px;
  border-radius: 10px !important;
  padding: 26px 21px 35px 30px !important;
`;

export const TTooltip = styled(ReactTooltip)`
  width: max-content;
  max-width: max-content;
  /* padding: 26px 30px 28px 30px !important; */
  padding: 21px !important;
  border-radius: 3px !important;
  font-size: 12px;
  box-shadow: 1px -1px 6px 0px rgba(0, 0, 0, 0.07);
`;

export const TTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  // color: ${({ theme }) => theme.colors.black[200]};
`;
export const TContent = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* color: ${({ theme }) => theme.colors.black[200]}; */
  /* color:red; */
`;
