import styled from 'styled-components';
import Checkbox from '../../../../../components/shared/Checkbox';

export const TMarkerBlock = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const TCheckbox = styled(Checkbox)`
  padding: 14px 24px;
  width: 510px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  & > div > input:disabled + span  {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.gray[100]};
  }
`;

export const TCheckboxContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  
  & > * {
    margin-bottom: 2px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  & div {
    display: flex;
    align-items: center;
    height: 100%;
    ${TCheckbox} {
      width: 176px;
      height: 100%;
      & div:last-child {
        display: flex;
        align-self: center;
        justify-content: center;
      }
    }
  }
`;

export const TLabel = styled.div`
  line-height: 24px;
  font-size: 16px;
`;