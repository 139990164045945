import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Input } from "../../../../componentsNew/Input";

import { Button } from "../../../../componentsNew/Button";

import { Description } from "../../../../componentsNew/Description";
import { useDispatch, useSelector } from "react-redux";
import { selectedAmbulatoryCardSelector } from "../../redux/patient";
import {
  isSearchedSelector,
  isCreateNewDoctorProfileSelector,
  doctorsSelector,
  lastNameSelector,
  setIsSearched,
  resetReferrer,
  setLastName,
} from "../../redux/referrer";
import { useDoctors } from "../../hooks/useDoctors";
import { DoctorFound } from "./components/DoctorFound";
import { DoctorNotFound } from "./components/DoctorNotFound";
import { DoctorCreateNewProfile } from "./components/DoctorCreateNewProfile";

export const Referrer = () => {
  const dispatch = useDispatch();

  const selectedAmbulatoryCard = useSelector(selectedAmbulatoryCardSelector);

  const searchLastName = useSelector(lastNameSelector);

  const isSearched = useSelector(isSearchedSelector);

  const isCreateNewDoctorProfile = useSelector(isCreateNewDoctorProfileSelector);

  const doctors = useSelector(doctorsSelector);

  const { searchDoctors } = useDoctors();

  const onSearch = async () => {
    if (searchLastName && selectedAmbulatoryCard?.organizationId) {
      await searchDoctors({
        lastName: searchLastName,
        organization: selectedAmbulatoryCard?.organizationId,
      });
    }
    dispatch(setIsSearched(true));
  };

  const onClear = async (): Promise<void> => {
    dispatch(resetReferrer());
    dispatch(setIsSearched(false));
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Данные направителя</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <Description>
          УЗ направляющей стороны должно совпадать с учреждением, выдавшим
          амбулаторную карту пациенту
        </Description>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="УЗ"
            required={true}
            placeholder=""
            value={selectedAmbulatoryCard?.organizationName || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Фамилия"
            required={true}
            placeholder=""
            value={searchLastName || ""}
            onChange={(event) => {
              dispatch(setLastName(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledPersonalDataButtonsContainer>
        <Button
          size="L"
          type="SECONDARY"
          text="Очистить"
          onClick={() => {
            onClear();
          }}
        />

        <Button
          size="L"
          type="PRIMARY"
          text="Найти врача"
          onClick={() => {
            onSearch();
          }}
          disabled={!searchLastName}
        />
      </StyledPersonalDataButtonsContainer>

      {
        isSearched
          ? (
            isCreateNewDoctorProfile
              ? <DoctorCreateNewProfile />
              : doctors?.length
                ? <DoctorFound />
                : <DoctorNotFound />
            )
          : null
      }

    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledPersonalDataButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 32px;
`;
