import { FC, InputHTMLAttributes, ReactNode, forwardRef, useCallback, MouseEvent } from 'react';
import { TInputWr, TIconWr, TError, TInput, TLabel } from './styled';

export interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  error?: boolean | null;
  errorText?: string;
  iconRight?: ReactNode;
  onRightIconClick?: () => void;
  iconLeft?: ReactNode;
  onLeftIconClick?: () => void;
  onRightIconMouseDown?: (event:MouseEvent) => void;
  onRightIconMouseLeave?: () => void;
  onRightIconMouseUp?: () => void;
  className?: string;
  bordered?: boolean;
  label?: string;
  maxLength?: number;
  marginTop?: number;
  readOnly?:boolean;
}

const Input: FC<IInput> = forwardRef((props, ref) => {
  const {
    readOnly,
    placeholder,
    label,
    error,
    iconRight,
    iconLeft,
    onRightIconClick,
    onRightIconMouseDown,
    onRightIconMouseLeave,
    onRightIconMouseUp,
    onLeftIconClick,
    errorText,
    className,
    bordered,
    maxLength,
    type = 'text',
    marginTop,
    ...restProps
  } = props;

  const onKeyDown = useCallback((e) => {
    if (type === 'number') {
      (['e', 'E', '+', '-'].includes(e.key) || [38, 40].includes(e.keyCode)) && e.preventDefault();
    }
  }, [type]);

  const onWheel = useCallback((e) => { e.currentTarget.blur() }, []);

  const onInput = useCallback((e) => {
    if (maxLength && e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength)
    }
  }, [maxLength])

  return (
    <div className={className}>
      <TInputWr>
        {label && <TLabel>{label}</TLabel>}
        {!!iconLeft && (
          <TIconWr 
            isLeft 
            withLabel={!!label} 
            onClick={() => onLeftIconClick?.()}
            onMouseDown={(event) => onRightIconMouseDown?.(event)}
            onMouseLeave={() => onRightIconMouseLeave?.()}
            onMouseUp={() => onRightIconMouseUp?.()}
          >
            {iconLeft}
          </TIconWr>
        )}
        <TInput
          className={`${type}-input-class`}
          // @ts-ignore
          ref={ref}
          onKeyDown={onKeyDown}
          hasIconRight={!!iconRight}
          hasIconLeft={!!iconLeft}
          onWheel={onWheel}
          error={!!error}
          bordered={!!bordered}
          autoComplete='off'
          {...restProps}
          placeholder={placeholder}
          type={type}
          onInput={onInput}
          marginTop={marginTop}
          readOnly={readOnly}
        />
        {!!iconRight && (
          <TIconWr
            withLabel={!!label}
            onClick={() => onRightIconClick?.()}
            onMouseDown={(event) => onRightIconMouseDown?.(event)}
            onMouseLeave={() => onRightIconMouseLeave?.()}
            onMouseUp={() => onRightIconMouseUp?.()}
          >
            {iconRight}
          </TIconWr>
        )}
        

      </TInputWr>
      {!!error && !!errorText && <TError>{errorText}</TError>}
    </div>
  );
});

export default Input;
