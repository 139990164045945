import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  dateSelector,
  setIsCreateNewDoctorProfile,
  selectedDoctorSelector,
  setDoctorToggled,
  setSelectedDoctor,
  doctorsSelector,
  searchProcessSelector,
  setDate,
} from "../../../../redux/referrer";
import { InputWithRadioToggle } from "../../../../../../componentsNew/InputWithRadioToggle";
import { Button } from "../../../../../../componentsNew/Button";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { Description } from "../../../../../../componentsNew/Description";
import { Colors } from "../../../../../../styles/themeNew";

export const DoctorFound = () => {
  const dispatch = useDispatch();

  const searchProcess = useSelector(searchProcessSelector);
  const doctors = useSelector(doctorsSelector);
  const selectedDoctor = useSelector(selectedDoctorSelector);

  const date = useSelector(dateSelector);

  if (!selectedDoctor.ulid && doctors && doctors?.length > 1) {
    return (
      <StyledContainer>
        <StyledTitleContainer>
          <Title level="2">Найдено несколько специалистов</Title>
        </StyledTitleContainer>

      <StyledRowContainer>
        <Description>
          Выберите подходящего или создайте другой профиль
        </Description>
      </StyledRowContainer>

        <StyledFoundPatientListContainer>
          {doctors.map((_doctor, index) => (
            <InputWithRadioToggle
              required={false}
              placeholder=""
              value={`${_doctor.userProfile.lastName} ${_doctor.userProfile.firstName} ${_doctor.userProfile.middleName}`}
              onChange={(event) => { }}
              disabled={true}
              onToggle={() => {
                dispatch(setDoctorToggled(index));
              }}
              index={index}
              isToggled={_doctor.isSelected}
            />
          ))}
        </StyledFoundPatientListContainer>

        <StyledSelectButtonContainer>
          <Button
            size="L"
            type="PRIMARY"
            text="Выбрать специалиста"
            onClick={() => {
              dispatch(setSelectedDoctor());
            }}
            color={Colors.GREEN[500]}
          />
        </StyledSelectButtonContainer>

        <StyledSelectButtonContainer>
          <Button
            size="L"
            type="PRIMARY"
            text="Добавть нового специалиста"
            onClick={() => {
              dispatch(setIsCreateNewDoctorProfile(true));
            }}
            color={Colors.BRAND[500]}
          />
        </StyledSelectButtonContainer>
      </StyledContainer>
    );
  } else {
    if (!selectedDoctor.ulid) {
      dispatch(setDoctorToggled(0));
      dispatch(setSelectedDoctor());
    };
  }


  if (!searchProcess) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Специалист найден. Проверьте данные</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <Description>Если данные неверные, создайте другой профиль</Description>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="ФИО"
            required={true}
            placeholder=""
            value={`${selectedDoctor?.userProfile.lastName} ${selectedDoctor?.userProfile.firstName} ${selectedDoctor?.userProfile.middleName}`}
            disabled={true}
            onChange={(event) => {}}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <Title level="3">Дата направления на исследование</Title>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Datepicker
            required={true}
            title="Дата"
            placeholder=""
            value={date || ""}
            onChange={(event) => {
              dispatch(setDate(event.target.value));
            }}
          />
        </StyledInputContainer>
      </StyledRowContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledFoundPatientListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSelectButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
