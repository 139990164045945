import styled from "styled-components";
import { Title } from "../../../../componentsNew/Title";
import { Colors } from "../../../../styles/themeNew";
import { Checkbox } from "../../../../componentsNew/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  markersSelector,
  selectedMarkersSelector,
  toggleMarker,
} from "../../redux/markers";
import { useMarkers } from "../../hooks/useMarkers";
import { useEffect, useState } from "react";
import { selectedLocalizationSelector } from "../../redux/clinicalData";
import { CreateQuestionModal } from "../CreateQuestionModal";
import { useCreate } from "../../hooks/useCreate";
import { createReferralErrorsSelector } from "../../redux/common";

import {
  createdReferralNumberSelector,
  createQuestionModalActiveSelector,
  setCreateQuestionModalActive
} from "../../redux/common";
import { CreateSuccessModal } from "../CreateSuccessModal";

export const Markers = () => {
  const dispatch = useDispatch();
  const { createIhcReferral } = useCreate();
  const [needShowSuccessModal, setNeedShowSuccessModal] = useState<boolean>(false);
  const markers = useSelector(markersSelector);
  const needShowQuestionModal = useSelector(createQuestionModalActiveSelector);
  const referralNumber = useSelector(createdReferralNumberSelector);
  const selectedMarkers = useSelector(selectedMarkersSelector);

  const selectedLocalization = useSelector(selectedLocalizationSelector);

  const refferalErrors = useSelector(createReferralErrorsSelector);

  const { searchMarkers } = useMarkers();

  const effect = async () => {
    if (!selectedLocalization?.value) {
      return;
    }

    searchMarkers({ localization: parseInt(selectedLocalization?.value, 10) });
  };

  useEffect(() => {
    effect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Выбор маркеров</Title>
      </StyledTitleContainer>

      <StyledTitleContainer2>Рекомендуемые маркеры</StyledTitleContainer2>

      <StyledTitlesContainer>
        <StyledMarkersTitleContainer>Маркеры</StyledMarkersTitleContainer>
        <StyledMedicinesTitleContainer>
          Лекарственные средства
        </StyledMedicinesTitleContainer>
      </StyledTitlesContainer>
      {
        needShowQuestionModal && (
          <CreateQuestionModal
            onConfirm={
              async () => {
                await createIhcReferral()
                  .then(() => refferalErrors ? null : setNeedShowSuccessModal(true))
                  .finally(() => dispatch(setCreateQuestionModalActive(false)));
              }}
            onCancel={() => dispatch(setCreateQuestionModalActive(false))} />
        )
      }
      {
        needShowSuccessModal && (
          <CreateSuccessModal referralNumber={`${referralNumber}`} />
        )
      }
      <StyledListContainer>
        {markers.map((marker) => (
          <StyledRowContainer>
            <StyledMarkerContainer>
              <Checkbox
                checked={Boolean(
                  selectedMarkers.find(
                    (selectedMarker) => selectedMarker.id === marker.id
                  )
                )}
                text={marker.marker}
                onClick={() => {
                  dispatch(toggleMarker(marker));
                }}
              />
            </StyledMarkerContainer>
            <StyledMedicinesContainer>
              {marker?.Therapy?.join(", ")}
            </StyledMedicinesContainer>
          </StyledRowContainer>
        ))}
      </StyledListContainer>

    {(refferalErrors)
      ?
        <StyledRedTextContainer>
          <StyledTitleContainerRed>Ошибка создания направления: </StyledTitleContainerRed>
          <br/>
          <pre>{JSON.stringify(refferalErrors, null, 2)}</pre>
        </StyledRedTextContainer>
      : null
    }
    </StyledContainer>
    );
};


const StyledRedTextContainer = styled.div`
  color: red;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  padding-bottom: 200px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
`;

const StyledTitleContainerRed = styled.div`
  color: red;
  font-size: 16px;
  font-weight: 400;
`;


const StyledTitleContainer2 = styled.div`
  color: ${Colors.MONOCHROM[400]};
  font-size: 16px;
  font-weight: 400;
`;

const StyledTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const StyledMarkersTitleContainer = styled.div`
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;

  width: 28.2%;
`;

const StyledMedicinesTitleContainer = styled.div`
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;

  width: 70%;
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;
`;

const StyledMarkerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 30%;
`;

const StyledMedicinesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 56px;

  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background: white;

  padding: 0 16px;

  color: ${Colors.MONOCHROM[400]};
  font-size: 16px;
  font-weight: 400;

  width: 70%;
`;
