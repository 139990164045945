export const REFERRAL_STEPS_DATA: Array<{ code: string; name: string }> = [
  {
    code: 'BIO_MATERIAL_RECEIPT',
    name: 'Приeм биоматериала',
  },
  {
    code: 'MORPHOLOGICAL_CHARACTERIZATION',
    name: 'Морфологическая характеристика',
  },
  {
    code: 'RESEARCH_IMPLEMENTATION',
    name: 'Реализация исследований',
  },
  {
    code: 'CONCLUSION_FORMATION',
    name: 'Формирование заключения',
  },
  {
    code: 'REFERRAL_CLOSED',
    name: 'Направление закрыто',
  },
];

export const REFERRAL_STEPS_CODE: Array<string> = [
  'BIO_MATERIAL_RECEIPT',
  'MORPHOLOGICAL_CHARACTERIZATION',
  'RESEARCH_IMPLEMENTATION',
  'CONCLUSION_FORMATION',
  'REFERRAL_CLOSED',
];

interface IFLOW_STEPS_STATUSES {
  bio_pending: number;
  morphology_pending: number;
  analysis_pending: number;
  analysis_absent: number;
  analysis_in_progress: number;
  conclusion_pending: number;
  referral_completed: number;
  [key: string]: number;
}

export const FLOW_STEPS_STATUSES: IFLOW_STEPS_STATUSES = {
  bio_pending: 1,
  morphology_pending: 2,
  analysis_pending: 3,
  analysis_absent: 3,
  analysis_in_progress: 3,
  conclusion_pending: 4,
  referral_completed: 5,
};

export const FLOW_STEPS_EXAMINATION = [
  'Попытка №1',
  'Попытка №2',
  'Попытка №3',
  'Исследование завершено',
];
