// eslint-disable-next-line
import { access } from 'fs';
import { Dispatch } from 'react';
import {
  getMolecularProfiling,
  getMolecularProfilingFailure,
  getMolecularProfilingSuccess,
  cancelMolecularProfiling,
  cancelMolecularProfilingSuccess,
  cancelMolecularProfilingFailure,
  resetMolecularProfiling,
  getMolProPatientReferrals,
  getMolProPatientReferralsSuccess,
  getMolProPatientReferralsFailure,
  changeMolProPaymentAndSpeedType,
  changeMolProPaymentAndSpeedTypeSuccess,
  changeMolProPaymentAndSpeedTypeFailure,
  confirmMolProBioMaterial,
  confirmMolProBioMaterialSuccess,
  confirmMolProBioMaterialFailure,
  postNewBioMatNumbers,
  postNewBioMatNumbersSuccess,
  postNewBioMatNumbersFailure,
  putConfirmMorphologicalGlass,
  putConfirmMorphologicalSuccess,
  confirmMorphologicalStatus,
  confirmMorphologicalStatusSuccess,
  confirmMorphologicalStatusFailure,
  createMorphologicalCharacteristic, 
  createMorphologicalCharacteristicSuccess, 
  createMorphologicalCharacteristicFailure,
  updateReferralMarkerList,
  updateReferralMarkerListSuccess,
  updateReferralMarkerListFailure,
  createExamination,
  createExaminationSuccess,
  createExaminationFailure,
  updateExaminationBlueprints,
  updateExaminationBlueprintsSuccess,
  updateExaminationBlueprintsFailure,
  confirmMorphMarkersDataConfirmStatus,
  confirmMorphMarkersDataConfirmStatusSuccess,
  confirmMorphMarkersDataConfirmStatusFailure,
  registryMarkerList,
  registryMarkerListSuccess,
  registryMarkerListFailure,
  registryCreateNextStep,
  registryCreateNextStepSuccess,
  registryCreateNextStepFailure,
  postCreateNewBioMatSubsNumber,
  postCreateNewBioMatSubsNumberSuccess,
  postCreateNewBioMatSubsNumberFailure,
  patchUpdateNewBioMatSubs,
  patchUpdateNewBioMatSubsSuccess,
  patchUpdateNewBioMatSubsFailure,
  commentMolProCreate,
  commentMolProCreateSuccess,
  commentMolProCreateFailure,
  confirmReferralCompliteStatus,
  confirmReferralCompliteStatusSuccess,
  confirmReferralCompliteStatusFailure,
  getSavedMolProCharacteristic,
  setSavedMolProCharacteristic,
  getSavedMolProCharacteristicSuccess,
  getSavedMolProCharacteristicFailure,
  setSavedMolProCharacteristicSuccess,
  setSavedMolProCharacteristicFailure,
} from '.';
import axiosInstance from '../../api/axiosInstance';
import {
  IExaminationCreate,
  IUpdateReferralMarkerList,
  IExaminationBlueprintsUpdateReq,
  ICreateNextStep,
  ISubBlockCreate,
  ISubBlockSendUpdate,
  IMorphologyCreate
} from './model';

export const fetchMolecularProfiling = (access: string, ref: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getMolecularProfiling());

  try {
    const response = await axiosInstance(access).get(`registry/referral/mol_pro/${ref}`);

    dispatch(getMolecularProfilingSuccess(response.data ?? null));
  } catch (error:any) {
    dispatch(getMolecularProfilingFailure(error?.response?.data));
  }
}

export const patchMolecularProfilingCancellation = (access:string, ref:string, cancellation_reason:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(cancelMolecularProfiling());
  const data = {
    status_cancellation:true,
    cancellation_reason,
    date_of_cancellation: new Date().toISOString()
  }
  try {
    const response = await axiosInstance(access).patch(`registry/referral/cancel/${ref}`,data);
    dispatch(cancelMolecularProfilingSuccess(response.data ?? null));
    dispatch(resetMolecularProfiling())
  } catch (error:any) {
    dispatch(cancelMolecularProfilingFailure(error?.response?.data));
  }
}

export const postMolecularProfilingCancellationRequest = (access:string, referral:number, comment:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(cancelMolecularProfiling());
  const data = {
    referral,
    status:'Cancellation',
    comment
  }
  try {
    await axiosInstance(access).post(`registry/referral/request/create/`,data);
    dispatch(resetMolecularProfiling())
  } catch (error:any) {
    dispatch(cancelMolecularProfilingFailure(error?.response?.data));
  }
}

export const patchMolecularProfilingCancellationRequest = (access:string, id:number, confirmation:boolean, comment?:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(cancelMolecularProfiling());
  const data:{ [key: string]: string | boolean } = {
    status:'Cancellation',
    pending: false,
    confirmation
  }

  if(comment) data['comment'] = comment;
  if(confirmation) data['date_confirmation'] = new Date().toISOString();

  try {
    await axiosInstance(access).patch(`registry/referral/request/${id}/`,data);
    dispatch(resetMolecularProfiling())
  } catch (error:any) {
    dispatch(cancelMolecularProfilingFailure(error?.response?.data));
  }
}

export const fetchMolProPatientReferrals = (access:string, ulid:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getMolProPatientReferrals());
  try {
    const response = await axiosInstance(access).get(`registry/referral/list/?patient__ulid=${ulid}`);
    dispatch(getMolProPatientReferralsSuccess(response.data));
  } catch (error:any) {
    dispatch(getMolProPatientReferralsFailure(error?.response?.data));
  }

}

export const patchReferralChangePaymentAndSpeedType = (access:string, ulid:string, paymentType:string, speedType: string) => async (dispatch: Dispatch<unknown>) => {
  const data = {payment_type: paymentType, speed_type: speedType};
  dispatch(changeMolProPaymentAndSpeedType());
  try {
    const response = await axiosInstance(access).patch(`registry/referral/update/payment/${ulid}`,data);
    dispatch(changeMolProPaymentAndSpeedTypeSuccess(response.data));
  } catch (error:any) {
    dispatch(changeMolProPaymentAndSpeedTypeFailure(error?.response?.data));
  }
}

export const patchMolecularProfilingDataConfirm = (access: string, ulid: string) => async (dispatch: Dispatch<unknown>) => {
  const data = {status: 'morphology_pending'}
  dispatch(confirmMolProBioMaterial());
  try {
    await axiosInstance(access).patch(`registry/referral/update/status/${ulid}`, data);
    dispatch(confirmMolProBioMaterialSuccess());
  } catch (error:any) {
    dispatch(confirmMolProBioMaterialFailure(error?.response?.data))
  }
}
export const postMolecularProfilingComment = (access: string, referral: number, stage: string, text: string) => async (dispatch: Dispatch<unknown>) => {
  const data = { referral, stage, text}  
  dispatch(commentMolProCreate());
  try {
    await axiosInstance(access).post(`registry/referral/commentary/create/`, data);
    dispatch(commentMolProCreateSuccess());
  } catch (error:any) {
    dispatch(commentMolProCreateFailure(error?.response?.data))
  }
}

export const postNewBioMaterialNumbers = (access: string, data:{block_cyphers:any[],slide_cyphers:any[]}) => async (dispatch: Dispatch<unknown>) => {
  dispatch(postNewBioMatNumbers());
  try {
    await axiosInstance(access).post(`bio/cyphers/create_list/`, data);
    dispatch(postNewBioMatNumbersSuccess());
  } catch (error:any) {
    dispatch(postNewBioMatNumbersFailure(error?.response?.data))
  }

}

export const postNewBioMaterialSubBlocksCreate = (access: string, data:ISubBlockCreate[]) => async (dispatch: Dispatch<unknown>) => {
  dispatch(postCreateNewBioMatSubsNumber());
  try {
    const response = await axiosInstance(access).post(`bio/block/sub/create/`, data);
    dispatch(postCreateNewBioMatSubsNumberSuccess(response.data));
  } catch (error:any) {
    dispatch(postCreateNewBioMatSubsNumberFailure(error?.response?.data))
  }
}
export const postNewBioMaterialSubBlocksUpdate = (access: string, data:ISubBlockSendUpdate[]) => async (dispatch: Dispatch<unknown>) => {
  dispatch(patchUpdateNewBioMatSubs());
  try {
    await axiosInstance(access).patch(`bio/block/sub/update/`, data);
    dispatch(patchUpdateNewBioMatSubsSuccess());
  } catch (error:any) {
    dispatch(patchUpdateNewBioMatSubsFailure(error?.response?.data))
  }
}

export const putMorphologicalGlassesFilled = (access: string, data: any[]) => async (dispatch: Dispatch<unknown>) => {
  dispatch(putConfirmMorphologicalGlass());
  try {
    await axiosInstance(access).put('bio/slide/update/list/', data);
    dispatch(putConfirmMorphologicalSuccess());
  } catch (error:any) {
    dispatch(postNewBioMatNumbersFailure(error?.response?.data))
  }
}

export const postMorphologicalCharacteristicCreate = (access: string, data: IMorphologyCreate) => async (dispatch: Dispatch<unknown>) => {
  dispatch(createMorphologicalCharacteristic());
  try {
    await axiosInstance(access).post('registry/morphology/create/', data);
    dispatch(createMorphologicalCharacteristicSuccess());
  } catch (error: any) {
    dispatch(createMorphologicalCharacteristicFailure(error?.response?.data))
  }
}

export const patchMorphologicalDataConfirm = (access: string, ulid: string) => async (dispatch: Dispatch<unknown>) => {
  const data = {status: 'markers_validation_pending'}
  dispatch(confirmMorphologicalStatus());
  try {
    await axiosInstance(access).patch(`registry/referral/update/status/${ulid}`, data);
    dispatch(confirmMorphologicalStatusSuccess());
  } catch (error:any) {
    dispatch(confirmMorphologicalStatusFailure(error?.response?.data))
  }

}

export const postUpdateReferralMarkerList = (access: string, data:IUpdateReferralMarkerList[]) => async (dispatch: Dispatch<unknown>) => {
  dispatch(updateReferralMarkerList());
  try {
    const response = await axiosInstance(access).post(`registry/referral/marker/list/uc/`, data);
    dispatch(updateReferralMarkerListSuccess(response.data ?? null));
  } catch (error:any) {
    dispatch(updateReferralMarkerListFailure(error?.response?.data))
  }

}


export const postExaminationCreate = (access: string, data:IExaminationCreate) => async (dispatch: Dispatch<unknown>) => {
  dispatch(createExamination());
  try {
    await axiosInstance(access).post(`registry/examination/create/`, data);
    dispatch(createExaminationSuccess());
  } catch (error:any) {
    dispatch(createExaminationFailure(error?.response?.data))
  }
  
}


export const putExaminationBlueprintsUpdate = (access: string, id:number, data:IExaminationBlueprintsUpdateReq) => async (dispatch: Dispatch<unknown>) => {
  dispatch(updateExaminationBlueprints());
  try {
    const response = await axiosInstance(access).put(`registry/blueprint/update/${id}`, data);
    dispatch(updateExaminationBlueprintsSuccess(response.data));
  } catch (error:any) {
    dispatch(updateExaminationBlueprintsFailure(error?.response?.data))
  }
  
}

export const postExaminationBlueprintsNumberUpdate = (access: string, id:number, data:string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(updateExaminationBlueprints());
  try {
    const response = await axiosInstance(access).post(`registry/blueprint/code/update/${id}`, {'number': data});
    dispatch(updateExaminationBlueprintsSuccess(response.data));
  } catch (error:any) {
    dispatch(updateExaminationBlueprintsFailure(error?.response?.data))
  }

}

export const patchMorphMarkersDataConfirm = (access: string, ulid: string) => async (dispatch: Dispatch<unknown>) => {
  const data = {status: 'analysis_pending'}
  dispatch(confirmMorphMarkersDataConfirmStatus());
  try {
    await axiosInstance(access).patch(`registry/referral/update/status/${ulid}`, data);
    dispatch(confirmMorphMarkersDataConfirmStatusSuccess());
  } catch (error:any) {
    dispatch(confirmMorphMarkersDataConfirmStatusFailure(error?.response?.data));
  }
}
export const patchMorphExaminationDataConfirm = (access: string, ulid: string) => async (dispatch: Dispatch<unknown>) => {
  const data = {status: 'conclusion_pending'}
  dispatch(confirmMorphMarkersDataConfirmStatus());
  try {
    await axiosInstance(access).patch(`registry/referral/update/status/${ulid}`, data);
    dispatch(confirmMorphMarkersDataConfirmStatusSuccess());
  } catch (error:any) {
    dispatch(confirmMorphMarkersDataConfirmStatusFailure(error?.response?.data));
  }
}

export const fetchRegistryMarkerList = (access: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(registryMarkerList());
  
  try {
    const response = await axiosInstance(access).get(`registry/marker/list/`);
    
    dispatch(registryMarkerListSuccess(response.data));
  } catch (error:any) {
    dispatch(registryMarkerListFailure(error?.response?.data));
  }
}

export const createNextStep = (access: string, data:ICreateNextStep) => async (dispatch: Dispatch<unknown>) => {
  dispatch(registryCreateNextStep());
  try {
    await axiosInstance(access).post(`registry/referral/step/create/`, data);
    dispatch(registryCreateNextStepSuccess());
  } catch (error:any) {
    dispatch(registryCreateNextStepFailure(error?.response?.data))
  }
}

export const patchReferralComplite = (access: string, ulid: string, id: number, fullRefNumber: string) => async (dispatch: Dispatch<unknown>) => {
  const statusData = {status: 'referral_completed'};
  const stepData    = {referral: id, status: 'referral_completed'};

  let isStepUpdated = await axiosInstance(access).post(`registry/referral/step/create/`, stepData);

  if(isStepUpdated?.data?.status === "referral_completed"){
    dispatch(confirmReferralCompliteStatus());
    try {
      await axiosInstance(access).patch(`registry/referral/update/status/${ulid}`, statusData);
      dispatch(confirmReferralCompliteStatusSuccess(fullRefNumber));
    } catch (error:any) {
      dispatch(confirmReferralCompliteStatusFailure(error?.response?.data));
    }
  }
}

export const getMolProCharacteristicSavedData = (access: string, referral_ulid: string) => async (dispatch: Dispatch<unknown>) => {
  dispatch(getSavedMolProCharacteristic());
  try {
    let response = await axiosInstance(access).get(`registry/morphology/memory/${referral_ulid}`);
    dispatch(getSavedMolProCharacteristicSuccess(response?.data))
  } catch (error:any) {
    dispatch(getSavedMolProCharacteristicFailure(error?.response?.data));
  }
};

export const postMolProCharacteristicSavedData = (access: string, referral_ulid: string, data:any) => async (dispatch: Dispatch<unknown>) => {
  dispatch(setSavedMolProCharacteristic())
  try {
    let response = await axiosInstance(access).post(`registry/morphology/memory/${referral_ulid}`,data);
    dispatch(setSavedMolProCharacteristicSuccess(response?.data));
  } catch (error:any) {
    dispatch(setSavedMolProCharacteristicFailure(error?.response?.data));
  }
};