import InputMask from 'react-input-mask';
import styled from "styled-components";
import { Colors } from "../../styles/themeNew";
import { useState, useEffect } from "react";

type Props = {
  title?: string | null;
  tooltip?: boolean;
  required?: boolean;
  value: string;
  placeholder?: string;
  mask?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errorMessage?: string;
  index: number;
  onToggle: (index: number) => void;
  isToggled: boolean; // New prop
};

export const InputWithRadioToggle = (props: Props) => {
  const [isValid, setIsValid] = useState(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [isRadioChecked, setIsRadioChecked] = useState(props.isToggled); // Initialize with is_toggled

  useEffect(() => {
    setInputValue(props.value || "");
  }, [props.value]);

  useEffect(() => {
    setIsRadioChecked(props.isToggled); // Update state when is_toggled changes
  }, [props.isToggled]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (newValue !== "" && newValue?.length > 1 && props.mask) {
      const regex = new RegExp(
        props.mask
          ? props.mask.replace(/9/g, '\\d').replace(/a/g, '[a-zA-Z]')
          : ''
      );

      setIsValid(
        regex.test(newValue) || newValue.length < props.mask?.replace(/9/g, '').replace(/a/g, '').length);
    } else {
      setIsValid(true);
    }

    props.onChange(event);
  };

  const toggleRadio = () => {
    const newCheckedState = !isRadioChecked;
    setIsRadioChecked(newCheckedState);
    if (newCheckedState) {
      props.onToggle(props.index);
    }
  };

  return (
    <StyledContainer>
      {Boolean(props.title) && (
        <StyledTitleContainer>
          {props.title}
          {props.required && (
            <StyledAsteriskContainer>*</StyledAsteriskContainer>
          )}
        </StyledTitleContainer>
      )}

      <StyledMainContainer>
        <StyledRadio type="radio" checked={isRadioChecked} onChange={toggleRadio} />
        <InputMask
          mask={props.mask || ""}
          value={inputValue}
          onChange={handleChange}
          disabled={props.disabled || !isRadioChecked}
        >
          {(inputProps: any) => (
            <StyledInput
              placeholder={props.placeholder}
              disabled={props.disabled || !isRadioChecked}
              onChange={handleChange}
              value={inputValue}
              isValid={isValid}
              {...inputProps}
            />
          )}
        </InputMask>

        {!isValid && inputValue && (
          <StyledErrorContainer>
            {props.errorMessage || "Ошибка ввода данных"}
          </StyledErrorContainer>
        )}

        {props.tooltip && (
          <StyledIconContainer>
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="none"
            >
              <circle cx="10" cy="10" r="9.5" stroke="#3E6AFF" />
              <line x1="10.0859"
                    y1="8.83203"
                    x2="10.0859"
                    y2="14.4987"
                    stroke="#3E6AFF"
                    strokeLinecap="round"
              />
              <circle cx="9.9974" cy="5.83333" r="0.833333" fill="#3E6AFF" />
            </svg>
          </StyledIconContainer>
        )}
      </StyledMainContainer>
    </StyledContainer>
  );
};

const StyledErrorContainer = styled.div`
  color: ${Colors.RED[500]};
  margin-top: 4px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  gap: 4px;
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;

const StyledInput = styled.input<{ isValid: boolean }>`
  height: 100%;
  margin: 0;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid ${({ isValid }) => (isValid ? Colors.MONOCHROM[200] : Colors.RED[500])};
  box-sizing: border-box;
  width: calc(100% - 24px);
  color: ${Colors.MONOCHROM[950]};
  font-size: 16px;
  font-weight: 400;

  &::placeholder {
    color: ${Colors.MONOCHROM[400]};
  }

  background: ${(props) => props.disabled ? Colors.MONOCHROM[100] : "transparent"};
`;

const StyledRadio = styled.input`
  margin-right: 10px;
  cursor: pointer; // Cursor changes to pointer when hovering over the radio button

  &:hover {
    cursor: pointer; // Ensure the pointer cursor only on hover
  }
`;

const StyledMainContainer = styled.div`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: default; // Default cursor for the rest of the element
`;