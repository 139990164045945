import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getTokens } from '../../../store/auth/selectors';
import {
  getHighPriorityMarkers,
  getLowPriorityMarkers,
} from '../../../store/markers/selectors';
import { fetchMarkers } from '../../../store/markers/thunkActions';
import MarkerBlock from '../../CreateReferralPage/steps/MarkersSelection/MarkerBlock';

import {
  TFormWrapper,
  TIcon,
  TMarkersTitle,
  TVisibilityToggle,
  TWrapper,
} from './styled';
import arrowIcon from '../../../icons/arrow-select.svg';
import { resetMarkers } from '../../../store/markers';
import { getFromSessionStorage } from '../../../utils/sessionStorageHelpers';
import { useLocation } from 'react-router';
import {
  patchMorphMarkersDataConfirm,
  postExaminationCreate,
} from '../../../store/molecularProfiling/thunkActions';
import { FLOW_STEPS_STATUSES } from '../../../fixtures/StepsMolecularProfilingPage';
import { getMolProMarkerUpdatedList } from '../../../store/molecularProfiling/selectors';
import { resetReferralMarkerList } from '../../../store/molecularProfiling';
import { getServiceUsers } from '../../../store/serviceUsers/selectors';
import { fetchServiceUsers } from '../../../store/serviceUsers/thunkActions';
import { IBiologicalMaterial } from '../../../store/molecularProfiling/model';
import { resetResearchSearchData } from '../../../store/research';
import { fetchResearch } from '../../../store/research/thunkActions';
import { Checkbox } from '../../../componentsNew/Checkbox';
import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';

interface IComponentProps {
  data?: any;
  [index: string]: any;
}

const MarkersValidation: FC<IComponentProps> = (data) => {
  const dispatch = useDispatch();
  const tokens = useSelector(getTokens);
  const path = useLocation();

  const highPriorityMarkers = useSelector(getHighPriorityMarkers);
  const lowPriorityMarkers = useSelector(getLowPriorityMarkers);
  const updatedMarkersListFromServer = useSelector(getMolProMarkerUpdatedList);

  const [isEditPageActive, setEditPageActive] = useState(false); //trigger edit button
  const [visible, setVisible] = useState(false); //is visible lowPriorityMarkers

  //const [checkedIds, setCheckedIds]           = useState<number[][]>([]);   //checked numbers id
  const [checkedMarkers, setCheckedMarkers] = useState<number[]>([]);
  const [validateMarkersActiv, setValidateMarkersActiv] = useState(false);
  const [isBloodChecked, setBloodChecked] = useState<boolean>(false); //checked blood on referral create
  const [referralMarkersId, setReferralMarkersId] = useState<number[]>([]);
  const [morphologName, setMorphologName] = useState('');

  const referralULD = useMemo(
    () => path.pathname.split('/').slice(-2)[0],
    [path]
  );

  const confirmedMarkers = useMemo(
    () => FLOW_STEPS_STATUSES[data.status] >= 4,
    [data?.status]
  );

  //Ger user profile
  const profile = useSelector(getProfile);
  const doctorsList = useSelector(getServiceUsers);

  useEffect(() => {
    if (!profile || !data?.status) return;

    if (!confirmedMarkers) {
      let { firstName = '', lastName = '', middleName = '' } = profile;
      setMorphologName(`${lastName} ${firstName} ${middleName}`.trim());
    }
    if (confirmedMarkers && data?.examinationReferral && doctorsList.length) {
      let coordinatorId = data?.examinationReferral?.coordinator;
      let morphologName = doctorsList.find(
        (item) => item.ulid === coordinatorId
      )?.userProfile;

      if (!morphologName) return;

      let { firstName = '', lastName = '', middleName = '' } = morphologName;

      setMorphologName(`${lastName} ${firstName} ${middleName}`.trim());
    }
  }, [
    profile,
    doctorsList?.length,
    confirmedMarkers,
    data,
    doctorsList,
    setMorphologName,
  ]);

  //delete old data and load markers by referral localization
  const resetAndReloadMarkers = useCallback(() => {
    if (tokens?.access && data?.diagnosis?.localization) {
      dispatch(resetMarkers());
      dispatch(fetchMarkers(tokens?.access, data?.diagnosis?.localization));
    }
  }, [dispatch, tokens, data]);

  //set values to avoid multiple rendering
  const setSavedData = useCallback(
    (data: any) => {
      setCheckedMarkers(data.checkedMarkers);
    },
    [
      setCheckedMarkers,
    ]
  );

  //get saved data
  const loadChanges = useCallback(async () => {
    let savedData: any;
    //load saved data
    let savedRawData =
      getFromSessionStorage('CHANGES_NOT_SAVED_MarkersData')?.[0] ?? false;
    //find saved changes by ulid
    if (savedRawData) {
      let parsedRawData = JSON.parse(savedRawData);
      savedData = parsedRawData.find(
        (savedItem: any) => savedItem.ulid === referralULD
      );
    }
    if (!!savedData && savedData !== -1) setSavedData(savedData);
  }, [referralULD, setSavedData]);

  //load doctors list if this stage is complite
  useLayoutEffect(() => {
    if (!data?.status) return;
    let isBloodOnReferralCreate = !!(data?.biologicalMaterial ?? []).filter(
      (bio: IBiologicalMaterial) => bio.type === 'Blood'
    )?.length;
    setBloodChecked(isBloodOnReferralCreate);

    if (FLOW_STEPS_STATUSES[data.status] >= 4 && tokens?.access) {
      dispatch(fetchServiceUsers(tokens?.access, { organizationId: 0 }));
    }
    if (FLOW_STEPS_STATUSES[data.status] === 3 && referralMarkersId)
      loadChanges();
  }, [data, dispatch, tokens, loadChanges, referralMarkersId, setBloodChecked]);

  //get markers by loalization at once
  useLayoutEffect(() => {
    resetAndReloadMarkers();
  }, [data?.diagnosis?.localization, resetAndReloadMarkers]);

  //set referral markers and markers names
  useEffect(() => {
    if (!data?.status) return;

    if (!data?.referralMarkers?.length) return;

    const mrkersId: number[] = [];

    let allReferralMarkersId = mrkersId.flat();

    //check markers referral by default
    setReferralMarkersId(allReferralMarkersId);
    setCheckedMarkers(allReferralMarkersId);
  }, [data, confirmedMarkers, morphologName]);

  //markers with low priority were selected
  const isLowPriorityMarkersCheckedByReferral = useMemo(() => {
    if (!lowPriorityMarkers.length && !checkedMarkers.length) return false;

    return lowPriorityMarkers
      .flat()
      .some((item: any) => checkedMarkers.includes(item.id));
  }, [checkedMarkers, lowPriorityMarkers]);

  const handleValidateMarkersConfirm = useCallback(() => {
    if (!validateMarkersActiv) return setValidateMarkersActiv(true);
    setEditPageActive(false);
    setValidateMarkersActiv(false);
  }, [
    validateMarkersActiv,
    setEditPageActive,
    setValidateMarkersActiv,
  ]);

  const finalCheckMarkersList = useCallback(() => {
    const checkedMarkersCollection = [];
    const markerNamesMap: { [index: number]: string } = {};
    const allMarkerData = highPriorityMarkers.concat(lowPriorityMarkers).flat();
    //collect data - {id:name}
    for (let markerObj of allMarkerData) {
      markerNamesMap[markerObj.id] = markerObj.marker.name;
    }

    for (let markerId of checkedMarkers) {
      checkedMarkersCollection.push(markerNamesMap[markerId]);
    }

    // @ts-ignore
    return [...new Set(checkedMarkersCollection)];
  }, [checkedMarkers, highPriorityMarkers, lowPriorityMarkers]);

  //Load updated markers
  useEffect(() => {
    if (updatedMarkersListFromServer?.length) {
      //examination data
      const updatedMarkers = updatedMarkersListFromServer.filter(
        (marker: any) => marker.status !== 'Убран координатором'
      );

      const bluprintData = finalCheckMarkersList().map((marker: string) => ({
        marker,
        bio: [],
        method: null,
      }));

      const examinationData = {
        referral: data.id,
        status: 'In Progress',
        examinationBlueprints: bluprintData,
        conclusion: '',
      };

      if (tokens?.access && updatedMarkers.length) {
        try {
          //send data to get template
          dispatch(postExaminationCreate(tokens.access, examinationData));
          //shange status
          dispatch(patchMorphMarkersDataConfirm(tokens.access, data?.ulid));
          //clear markers list
          dispatch(resetReferralMarkerList());
          //clear and update table
          if (tokens?.access)
            dispatch(
              fetchResearch(tokens?.access, {
                page: 1,
                pageSize: 10,
                tab: 'all',
              })
            );
          else dispatch(resetResearchSearchData());
        } catch (error) {
          console.error(error);
        }
      }
      handleValidateMarkersConfirm();
      setEditPageActive(false);
    }
  }, [
    data?.id,
    data?.ulid,
    tokens?.access,
    dispatch,
    handleValidateMarkersConfirm,
    updatedMarkersListFromServer,
    setEditPageActive,
    finalCheckMarkersList,
  ]);

  return (
    <TWrapper>
      <TFormWrapper column={true}>
        {!!lowPriorityMarkers.length && (
          <TMarkersTitle calorBalack={true}>
            Рекомендуемые маркеры
          </TMarkersTitle>
        )}

        {highPriorityMarkers.map((group: any[], index) => (
          <MarkerBlock
            key={index}
            items={group}
            blockIndex={index}
            isDisabled={!isEditPageActive}
            checkedByDefault={checkedMarkers}
            isBloodChecked={isBloodChecked}
          />
        ))}
        <StyledTitlesContainer>
          <StyledMarkersTitleContainer>Маркеры</StyledMarkersTitleContainer>
          <StyledMedicinesTitleContainer>
            Лекарственные средства
          </StyledMedicinesTitleContainer>
        </StyledTitlesContainer>
        <StyledListContainer>
          {data.referralMarkers.map(
            (marker: { therapy: any; marker: string; Therapy: any[] }) => (
              <StyledRowContainer>
                <StyledMarkerContainer>
                  <Checkbox text={marker.marker} isDisabled checked />
                </StyledMarkerContainer>
                <StyledMedicinesContainer>
                  {marker?.therapy?.join(', ')}
                </StyledMedicinesContainer>
              </StyledRowContainer>
            )
          )}
        </StyledListContainer>

        {!!lowPriorityMarkers.length && (
          <>
            <TVisibilityToggle
              visible={visible || isLowPriorityMarkersCheckedByReferral}
              onClick={() => setVisible(!visible)}
            >
              Другие маркеры <TIcon size={12} src={arrowIcon} />
            </TVisibilityToggle>

            {(visible || isLowPriorityMarkersCheckedByReferral) &&
              lowPriorityMarkers.map((group: any[], index) => (
                <MarkerBlock
                  key={index}
                  items={group}
                  blockIndex={index + highPriorityMarkers.length}
                  isDisabled={!isEditPageActive}
                  checkedByDefault={checkedMarkers}
                  isBloodChecked={isBloodChecked}
                />
              ))}
          </>
        )}
      </TFormWrapper>
    </TWrapper>
  );
};

export default MarkersValidation;

const StyledTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const StyledMarkersTitleContainer = styled.div`
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  width: 28.2%;
  margin-bottom: 4px;
`;

const StyledMedicinesTitleContainer = styled.div`
  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  width: 70%;
  margin-bottom: 4px;
`;

const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 4px;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;
`;

const StyledMarkerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
`;

const StyledMedicinesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 56px;

  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  background: white;

  padding: 0 16px;

  color: ${Colors.MONOCHROM[400]};
  font-size: 16px;
  font-weight: 400;

  width: 70%;
`;
