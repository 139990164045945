import styled from "styled-components";
import { Title } from "../../../../../../componentsNew/Title";
import { Input } from "../../../../../../componentsNew/Input";
import { Datepicker } from "../../../../../../componentsNew/Datepicker";
import { Radio } from "../../../../../../componentsNew/Radio";
import { Select } from "../../../../../../componentsNew/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  rfSearchBirthdateSelector,
  rfSearchFirstNameSelector,
  rfSearchLastNameSelector,
  rfSearchPatronymicSelector,
  selectedPatientConfirmedSelector,
  setSelectedPatientBirthdate,
  setSelectedPatientFirstName,
  setSelectedPatientLastName,
  setSelectedPatientPatronymic,
  setSelectedPatientGender,
  setSelectedPatientCountry,
  setSelectedPatientIdPassport,
  setSelectedPatientAddress,
  setSelectedPatientOms,
  setSelectedPatientSnils,
  setSelectedPatientConfirmed,
  setSelectedAmbulatoryCardNumber,
  setSelectedAmbulatoryCardOrganizationId,
  setSelectedAmbulatoryCardOrganizationName,
  selectedPatientSelector,
  selectedAmbulatoryCardSelector
} from "../../../../redux/patient";
import { Description } from "../../../../../../componentsNew/Description";
import { useHttpClient } from "../../../../../../hooks/useHttpClient";
import { useEffect, useState } from "react";
import { ClinicsService } from "../../../../../../services/Clinics";
import { Button } from "../../../../../../componentsNew/Button";

import { RF_CITIZEN__COUNTRY_CODE } from "../../../../../../constants/codes";
import { GENDERS, GENDER_MALE, GenderCode } from "../../../../../../constants/genders";

export const NewRfProfile = () => {
  const httpClient = useHttpClient();

  const [clinics, setClinics] = useState<any[]>([]);

  const dispatch = useDispatch();

  const rfSearchBirthdate = useSelector(rfSearchBirthdateSelector);
  const rfSearchFirstName = useSelector(rfSearchFirstNameSelector);
  const rfSearchLastName = useSelector(rfSearchLastNameSelector);
  const rfSearchPatronymic = useSelector(rfSearchPatronymicSelector);

  dispatch(setSelectedPatientBirthdate(rfSearchBirthdate || ""));
  dispatch(setSelectedPatientFirstName(rfSearchFirstName || ""));
  dispatch(setSelectedPatientLastName(rfSearchLastName || ""));
  dispatch(setSelectedPatientPatronymic(rfSearchPatronymic || ""));

  const selectedPatient = useSelector(selectedPatientSelector);
  const selectedAmbulatoryCard = useSelector(selectedAmbulatoryCardSelector);

  const isSelectedPatientFormFilled = Boolean(
    selectedPatient?.birthdate &&
    selectedPatient?.firstName &&
    selectedPatient?.lastName &&
    selectedPatient?.patronymic &&
    selectedPatient?.address &&
    selectedPatient?.oms &&
    selectedPatient?.idPassport &&
    selectedPatient?.gender &&
    selectedPatient?.snils &&
    selectedAmbulatoryCard?.number &&
    selectedAmbulatoryCard?.organizationId &&
    selectedAmbulatoryCard?.organizationName
  );

  const isSelectedPatientConfirmed = useSelector(selectedPatientConfirmedSelector);


  const effect = async () => {
    const clinicsService = new ClinicsService(httpClient);
    const _clinics = await clinicsService.searchClinics();

    if (_clinics?.length) {
      setClinics(_clinics);
    }
  };

  useEffect(() => {
    dispatch(setSelectedPatientCountry(RF_CITIZEN__COUNTRY_CODE));
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPatientConfirm = () => {
    dispatch(setSelectedPatientConfirmed(true));
  };

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <Title level="2">Совпадений не найдено. Создайте профиль</Title>
      </StyledTitleContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Фамилия"
            required={true}
            placeholder="--"
            value={selectedPatient?.lastName || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>
        <StyledInputContainer2>
          <Input
            title="Имя"
            required={true}
            placeholder="--"
            value={selectedPatient?.firstName || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            title="Отчество"
            placeholder="--"
            value={selectedPatient?.patronymic || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Datepicker
            required={true}
            title="Дата рождения"
            placeholder="--"
            value={selectedPatient?.birthdate || ""}
            onChange={(event) => { }}
            disabled={true}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledRowContainer2>
        {GENDERS.map((gender) => (
          <StyledInputContainer2 key={gender.code}>
            <Radio
              title={gender.code === GENDER_MALE ? "Пол" : undefined}
              selected={gender.code === selectedPatient?.gender}
              text={gender.name}
              onSelect={() => {
                dispatch(setSelectedPatientGender(gender.code as GenderCode));
              }}
              tooltip={false}
              required={
                gender.code === GENDER_MALE ? true : false
              }
              disabled={isSelectedPatientConfirmed}
            />
          </StyledInputContainer2>
        ))}
      </StyledRowContainer2>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            mask="9999 999999"
            title="Серия и номер паспорта"
            required={true}
            placeholder="xxxx xxxxxx"
            value={selectedPatient?.idPassport || ""}
            errorMessage="Пример: 1234 567890"
            onChange={(event) => {
              dispatch(setSelectedPatientIdPassport(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer>
          <Input
            title="Адрес регистрации"
            required={true}
            placeholder="--"
            value={selectedPatient?.address || ""}
            onChange={(event) => {
              dispatch(setSelectedPatientAddress(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer>
      </StyledRowContainer>

      <StyledRowContainer>
        <StyledInputContainer2>
          <Input
            mask="9999999999999999"
            title="Номер полиса ОМС"
            required={true}
            placeholder="xxxxxxxxxxxxxxxx"
            value={selectedPatient?.oms || ""}
            errorMessage="Пример: 1234567891234567"
            onChange={(event) => {
              dispatch(setSelectedPatientOms(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer2>

        <StyledInputContainer2>
          <Input
            mask="999-999-999 99"
            title="Номер СНИЛС"
            required={true}
            placeholder="xxx-xxx-xxx xx"
            value={selectedPatient?.snils || ""}
            errorMessage="Пример: 123-456-789 10"
            onChange={(event) => {
              dispatch(setSelectedPatientSnils(event.target.value));
            }}
            disabled={isSelectedPatientConfirmed}
          />
        </StyledInputContainer2>
      </StyledRowContainer>

      <StyledAmbulatoryCardContainer>
        <StyledRowContainer style={{ marginBottom: "16px" }}>
          <Title level="3">Амбулаторная карта</Title>
        </StyledRowContainer>

        <StyledRowContainer style={{ marginBottom: "8px" }}>
          <Description>
            Убедитесь, что выбранная амбулаторная карта зарегистрирована в
            учреждении направителя
          </Description>
        </StyledRowContainer>

        <StyledRowContainer2>
          <StyledInputContainer2>
            <Input
              title="Номер амбулаторной карты"
              required={true}
              placeholder="--"
              value={selectedAmbulatoryCard?.number || ""}
              onChange={(event) => {
                dispatch(setSelectedAmbulatoryCardNumber(event.target.value));
              }}
              disabled={isSelectedPatientConfirmed}
            />
          </StyledInputContainer2>

          <StyledInputContainer2>
            <Select
              title="УЗ"
              placeholder="--"
              required={true}
              options={clinics.map((clinic) => ({
                label: clinic.name_local_rus,
                value: String(clinic.id),
                selected: `${clinic.id}` === `${selectedAmbulatoryCard.organizationId}`,
              }))}
              onSelect={(option: { label: string, value: string}) => {
                dispatch(setSelectedAmbulatoryCardOrganizationId(Number(option.value)));
                dispatch(setSelectedAmbulatoryCardOrganizationName(option.label));
              }}
              disabled={isSelectedPatientConfirmed}
            />
          </StyledInputContainer2>
        </StyledRowContainer2>
      </StyledAmbulatoryCardContainer>

      {Boolean(!isSelectedPatientConfirmed) && (
        <StyledRowContainer>
          <Button
            type="PRIMARY"
            text="Подтвердить"
            onClick={() => {
              onPatientConfirm();
            }}
            disabled={!isSelectedPatientFormFilled}
          />
        </StyledRowContainer>
      )}
      {Boolean(isSelectedPatientConfirmed) && (
        <StyledRowContainer>
          <Button
            type="SECONDARY"
            text="Редактировать"
            onClick={() => {
              dispatch(setSelectedPatientConfirmed(false));
            }}
          />
        </StyledRowContainer>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const StyledTitleContainer = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledInputContainer2 = styled.div`
  width: 50%;
`;

const StyledRowContainer2 = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 16px;
`;

const StyledAmbulatoryCardContainer = styled.div``;
