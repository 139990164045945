import styled from 'styled-components';
import { Colors } from '../../../styles/themeNew';

export const TDetailsFormWr = styled.div``;

export const TDetailsItemWr = styled.div`
  position: relative;

  details[open] {
    margin-bottom: 8px;
    .arrow {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    summary {
      border: 1px solid ${Colors.BRAND[400]};
      border-radius: 6px;
    }
  }
`;

export const TDetails = styled.details`
  box-sizing: border-box;
  summary {
    cursor: pointer;
  }
  & > summary {
    margin-bottom: 4px;
  }
  & :last-child {
    margin-bottom: 0;
  }
`;

export const TDetailsContent = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  height: max-content;
  border-radius: 6px;
  padding: 24px 16px;
  margin-bottom: 6px;
  border: 1px solid ${Colors.BRAND[400]};
`;

export const TSummary = styled.summary`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: ${Colors.MONOCHROM[950]};
  list-style: none;
  height: 60px;
  border-radius: 10px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  padding: 0 16px;
  font-weight: 400;

  details[open] {
    display: none;
  }

  span {
    margin-bottom: 0;
    padding-left: 0;
  }
`;

export const TSummaryContent = styled.span<{ isSelected: boolean }>`
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.black[200]};
  font-style: normal;
  font-size: 16px;
  padding-left: ${({ isSelected }) => (isSelected ? '0px' : '40px')};
`;

export const TSummaryIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TSummaryIcon = styled.img`
  display: inline-block;
  width: 30px;
  height: 30px;
  padding-right: 20px;
  &.arrow {
    padding: 0;
    width: 24px;
    height: 24px;
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })<{
  disabled?: boolean;
}>`
  transform: scale(1.6);
  -ms-transform: scale(1.6); /* IE */
  -moz-transform: scale(1.6); /* FF */
  -webkit-transform: scale(1.6); /* Safari and Chrome */
  -o-transform: scale(1.6); /* Opera */
  transform: scale(1.6);
  padding: 10px;
  margin: 0 24px;
  accent-color: #7a78e9;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const TCheckbox = styled(Checkbox)``;

export const TSelectAll = styled.label`
  display: block;
  width: max-content;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.gray[200]};
  cursor: pointer;
`;
