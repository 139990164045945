import { Colors } from '../../styles/themeNew';
import { StepState, StepsType } from './types/Step';

export const getStepperColor = (stepState: StepState) => {
  if (
    stepState === StepState.DISABLED ||
    stepState === StepState.WITH_SUCCESS
  ) {
    return Colors.MONOCHROM[200];
  }

  if (stepState === StepState.SELECTED) {
    return Colors.GREEN[500];
  }

  return Colors.MONOCHROM[950];
};

export const getContentValue = (stepState: StepState) => {
  if (
    stepState === StepState.DISABLED ||
    stepState === StepState.WITH_SUCCESS
  ) {
    return 'close-quote';
  }

  return 'none';
};

export const getStepWidth = (type: StepsType) => {
  if (type === StepsType.STEPPER_SECONDARY) {
    return '0px';
  }
  return '200px';
};